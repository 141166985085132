import styled from 'styled-components'
import theme from 'theme'
export const SplashScreenContainer = styled.div`
  display: flex;
  .content-container {
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 0 10em;
    .heinken-beer-container,
    .empty {
      display: none;
    }
    @media screen and (min-width: 740px) {
      .heinken-beer-container {
        display: flex;
        flex: 2;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0% 100%;
        z-index: 1;
      }
      .empty {
        display: flex;
        flex: 2;
      }
    }

    .text-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .tick-headings-wrapper {
        display: flex;
        padding-bottom: 50px;
        img {
          width: 70px;
          height: 70px;
          margin-right: 20px;
        }
        .headings {
          display: flex;
          flex-direction: column;
          width: 440px;
          h1 {
            font-size: 45px;
            color: ${theme.text.splashScreen};
            font-weight: 900;
            line-height: 1;
            letter-spacing: 0.3px;
          }
          h4 {
            color: ${theme.text.splashScreen};
            font-size: 24px;
            font-weight: normal;
            padding-top: 15px;
          }
          p {
            color: ${theme.text.splashScreen};
            font-size: 16px;
            line-height: 1.33;
            letter-spacing: -0.14px;
            font-weight: 500;
            width: 330px;
            margin: 30px 0;
          }
          .anchor-wrapper {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
    @media screen and (min-width: 740px) {
      .text-container {
        flex: 3;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .content-container {
      padding: 0 3em;
      .text-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .tick-headings-wrapper {
          display: flex;
          padding-bottom: 0;
          img {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }
          .headings {
            display: flex;
            flex-direction: column;
            width: 350px;
            h1 {
              font-size: 35px;
              color: ${theme.text.splashScreen};
              font-weight: 900;
              line-height: 1;
              letter-spacing: 0.2px;
            }
            h4 {
              color: ${theme.text.splashScreen};
              font-size: 18px;
              font-weight: normal;
              padding-top: 15px;
            }
            p {
              color: ${theme.text.splashScreen};
              font-size: 12px;
              line-height: 1.33;
              letter-spacing: -0.14px;
              font-weight: 500;
              width: 330px;
              margin: 25px 0;
            }
            .anchor-wrapper {
              a {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .content-container {
      padding: 0 2em;
      .text-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .tick-headings-wrapper {
          display: flex;
          padding-bottom: 0;
          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
          .headings {
            display: flex;
            flex-direction: column;
            width: 285px;
            h1 {
              font-size: 26px;
              color: ${theme.text.splashScreen};
              font-weight: 900;
              line-height: 1;
              letter-spacing: 0.2px;
            }
            h4 {
              color: ${theme.text.splashScreen};
              font-size: 14px;
              font-weight: normal;
              padding-top: 15px;
            }
            p {
              color: ${theme.text.splashScreen};
              font-size: 10px;
              line-height: 1.33;
              letter-spacing: -0.14px;
              font-weight: 500;
              width: 250px;
              margin: 20px 0;
            }
            .anchor-wrapper {
              a {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .footer-container {
      padding: 0;
      .links-container {
        .links-wrapper {
          width: 440px;
          padding-left: 0;
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .footer-container {
      height: 120px;
      padding: 0;
      .links-container {
        .links-wrapper {
          width: 440px;
          padding-left: 110px;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .footer-container {
      padding: 0;
      .links-container {
        .links-wrapper {
          width: 380px;
          padding-left: 0;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .footer-container {
      height: 100px;
      padding: 0;
      .links-container {
        .links-wrapper {
          width: 200px;
          padding-left: 0;
          .heineken-logo-wrapper {
            img {
              padding-bottom: 10px;
              width: 80px;
            }
          }
          .social-network-wrapper {
            img {
              width: 15px;
            }
            .facebook-logo {
              width: 7px;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
`

export const Footer = styled.div`
  
  box-sizing: border-box;
  width: 100%;
  padding: 0 10em;

  background-color: ${theme.cta.primaryDark};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  .footer-inner-container {
    display: flex;
    padding: 0 1rem;
  }
  .links-wrapper {
    display: flex;
    flex: 3;
    align-items: center;
  }
      .heineken-logo-wrapper {
        padding-right: 10px;
        img {
          width: 92px;
        }
      }
    
  
  .cookie-url {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.8;
    color: #fff;
    padding-top: 5px;
    a {
      color: #fff;
      text-decoration: none;
      letter-spacing: -0.14px;
      text-align: right;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
  .cookie-url a {
    text-align: left;
  }
}
@media screen and (max-width: 739px) {
  .cookie-url {
    margin-left: auto;
  }
}
.colophon {
  flex: 3;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;
  padding-right: 15px;
}
.empty {
  display:none;
  flex: 0;
}

@media screen and (min-width: 740px) {
  .empty {
    display: flex;
    flex: 2;
  }
}
`

export const SocialNetworkIcons = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 1rem;
  }
  .facebook-logo {
    width: 8px;
    margin: 0 5px;
  }
`
