import React from 'react'
import styled from 'styled-components'
import Brand from 'models/entities/Brand'
import BrandSelection from 'components/NewEnquiry/Brands/BrandSelection'

const Container = styled.div`
  .section-heading {
    font-size: 12px;
    font-weight: 800;
    line-height: 1.75;
    color: #006e31;
    text-transform: uppercase;
  }
  .brand-selection {
    margin: 1.5rem 0;
  }
`

interface Props {
  value: Brand[]
  brands: Brand[]
  onChange: (field: string, value: Brand[]) => void
}

const Brands: React.FC<Props> = props => {
  const { value, brands, onChange } = props
  return (
    <Container>
      <span className="section-heading">SECTION 5.</span>
      <h4 className="bold">Which Brands Are You Interested In?</h4>
      <BrandSelection
        value={value}
        brands={brands}
        onChange={val => {
          onChange('brands', val)
        }}
      />
    </Container>
  )
}

export default Brands
