import React from 'react'
import styled from 'styled-components'
import RadioButton from 'components/FormElements/RadioButton'
import MonthSelector from 'components/MonthSelector'
import theme from 'theme'

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .label-container {
    flex-basis: 40%;
    margin-top: 1rem;
    h4 {
      white-space: nowrap;
    }
    .section-heading {
      font-size: 12px;
      font-weight: 800;
      line-height: 1.75;
      color: #006e31;
      text-transform: uppercase;
    }
  }
  .error {
    .radio-container {
      border: 1px solid ${theme.status.danger};
    }
    .options-container {
      padding-bottom: 0;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-width: 320px;
  flex-basis: 60%;
  margin-top: 1rem;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  .radio-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 52px;
    padding-left: 14px;
    background-color: ${theme.background.defaultAlt};
    border: 1px solid ${theme.background.defaultAlt};
    border-radius: 0.75rem;
    &:hover {
      background-color: ${theme.background.default};
      border: 1px solid ${theme.background.default};
      box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.4);
    }
    &.selected {
      background-color: ${theme.background.defaultSelected};
      border: 1px solid ${theme.background.defaultSelected};
    }
    &:first-child {
      margin-right: 8px;
    }
  }
`

const CalendarContainer = styled.div`
  .month-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;
    margin: -4px;
  }
  @media screen and (max-width: 750px) {
    .month-wrapper {
      .card-container {
        flex-basis: calc(50% - 8px);
      }
    }
  }
`

const ValidationError = styled.span`
  font-size: 0.75rem;
  color: ${theme.status.danger};
`

interface Values {
  tradeMonths: number[]
  tradeAllYear: boolean | null
}
interface Errors {
  tradeMonths?: string
  tradeAllYear?: string
}

interface Props {
  values: Values
  errors: Errors
  required?: boolean
  onChange: (field: string, value: boolean | number[]) => void
}

const SeasonalTrader: React.FC<Props> = ({
  values,
  errors,
  required,
  onChange,
}) => {
  const { tradeAllYear, tradeMonths } = values

  const labelAllYear = 'Do You Trade All Year Round?'
  const labelMonths =
    'Please indicate which months of the year your outlet is open'

  return (
    <Container>
      <div className="label-container">
        <span className="section-heading">SECTION 3.</span>
        <h4 className="bold">Do You Trade All Year Round?</h4>
      </div>
      <ContentWrapper>
        <label>{`${labelAllYear}${required ? ' *' : ''}`}</label>
        <div className={`${errors && errors.tradeAllYear ? 'error' : ''}`}>
          <OptionsContainer className="options-container">
            <RadioButton
              label="Yes"
              option={true}
              value={tradeAllYear}
              onChange={value => {
                onChange('tradeAllYear', value)
                onChange('tradeMonths', [])
              }}
            />
            <RadioButton
              label="No"
              option={false}
              value={tradeAllYear}
              onChange={value => {
                onChange('tradeAllYear', value)
              }}
            />
          </OptionsContainer>
          {errors && errors.tradeAllYear && (
            <ValidationError>{errors.tradeAllYear}</ValidationError>
          )}
        </div>
        {tradeAllYear === false && (
          <CalendarContainer>
            <label>{`${labelMonths}${required ? ' *' : ''}`}</label>
            <div className="month-wrapper">
              <MonthSelector
                value={tradeMonths}
                onChange={value => {
                  onChange('tradeMonths', value)
                }}
              />
            </div>
            {errors && errors.tradeMonths && (
              <ValidationError>{errors.tradeMonths}</ValidationError>
            )}
          </CalendarContainer>
        )}
      </ContentWrapper>
    </Container>
  )
}

export default SeasonalTrader
