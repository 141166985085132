import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import Checkbox from 'components/FormElements/Checkbox'
import moment from 'moment'

const Container = styled.div`
  display: flex;
  position: relative;
  border-radius: 0.5rem;
  width: 100%;
  min-height: 60px;
  height: 0px;
  background-color: ${theme.background.defaultAlt};
  cursor: pointer;
  .checkbox {
    position: absolute;
    right: 0;
    top: 20%;
    transform: translateY(-50%);
    padding: 8px;
    box-sizing: border-box;
  }
  &:hover {
    background-color: ${theme.background.default};
    box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.4);
  }
  &.selected {
    background-color: ${theme.background.defaultSelected};
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-basis: calc(25% - 8px);
  margin: 4px;
  min-width: 100px;
`

const Label = styled.label`
  width: 100%;
  top: 37%;
  text-align: center;
  position: absolute;
  color: ${theme.text.body};
  cursor: pointer;
  font-size: 0.875rem;
`

interface Props {
  value: number[]
  onChange: (value: number[]) => void
}

const MonthSelector: React.FC<Props> = props => {
  const { value, onChange } = props
  const options = moment.monthsShort()

  return (
    <>
      {options.map((o, i) => (
        <CardContainer className="card-container" key={i}>
          <Container
            className={`${value.indexOf(i + 1) !== -1 ? 'selected' : ''}`}
            onClick={() => {
              const index = value.indexOf(i + 1)
              index !== -1
                ? onChange(value.filter((v, i) => i !== index))
                : onChange(value.concat([i + 1]))
            }}
          >
            <Checkbox
              value={value.indexOf(i + 1) !== -1}
              onChange={e => {
                const index = value.indexOf(i + 1)
                index !== -1
                  ? onChange(value.filter((v, i) => i !== index))
                  : onChange(value.concat([i + 1]))
              }}
              size="16px"
            />
            <Label>{o.toUpperCase()}</Label>
          </Container>
        </CardContainer>
      ))}
    </>
  )
}

export default MonthSelector
