import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import InputText from 'components/FormElements/InputText'
import CheckboxGroup from 'components/FormElements/CheckboxGroup'
import ContactMethod from 'models/entities/ContactMethod'

interface Values {
  firstName: string
  lastName: string
  email: string
  mobile: string
  businessPhone: string
  contactMethod: ContactMethod[]
}

interface Errors {
  firstName?: string
  lastName?: string
  email?: string
  mobile?: string
  businessPhone?: string
  contactMethod?: string
}

interface Props {
  values: Values
  errors: Errors
  sectionNumber: string
  onChange: (field: string, value: string | string[] | ContactMethod[]) => void
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .label-container {
    flex-basis: 40%;
    margin-top: 1rem;
    h4 {
      white-space: nowrap;
    }
    .section-heading {
      font-size: 12px;
      font-weight: 800;
      line-height: 1.75;
      color: #006e31;
      text-transform: uppercase;
    }
    span {
      display: flex;
      max-width: 300px;
      color: ${theme.text.secondary};
      margin-top: 0.375rem;
    }
  }
  .fields-container {
    flex-basis: 60%;
    min-width: 320px;
    margin: 1rem 0 1.75rem 0;
  }
`

const options = [
  {
    key: ContactMethod.email,
    label: 'Email',
  },
  {
    key: ContactMethod.mobile,
    label: 'Mobile',
  },
  {
    key: ContactMethod.landline,
    label: 'Business Phone',
  },
]
const ContactDetails: React.FC<Props> = ({
  values,
  errors,
  onChange,
  sectionNumber,
}) => {
  const { firstName, lastName, email, mobile, businessPhone, contactMethod } =
    values
  return (
    <Container>
      <div className="label-container">
        <span className="section-heading">{`SECTION ${sectionNumber}.`}</span>
        <h4 className="bold">Your Contact Details</h4>
        <span>
          Please provide contact details for the key decision maker for this
          outlet.
        </span>
      </div>
      <div className="fields-container">
        <InputText
          required={true}
          label="First Name"
          value={firstName}
          validationError={errors.firstName}
          onChange={(e) => {
            onChange('firstName', e.target.value)
          }}
        />
        <InputText
          required={true}
          label="Last Name"
          value={lastName}
          validationError={errors.lastName}
          onChange={(e) => {
            onChange('lastName', e.target.value)
          }}
        />
        <InputText
          required={true}
          label="Email"
          value={email}
          type="text"
          validationError={errors.email}
          onChange={(e) => {
            onChange('email', e.target.value)
          }}
        />
        <InputText
          required={true}
          label="Mobile"
          value={mobile}
          type="tel"
          validationError={errors.mobile}
          onChange={(e) => {
            onChange('mobile', e.target.value)
          }}
        />
        <InputText
          required={true}
          label="Business Phone"
          value={businessPhone}
          type="tel"
          validationError={errors.businessPhone}
          onChange={(e) => {
            onChange('businessPhone', e.target.value)
          }}
        />
        <CheckboxGroup
          value={options.filter((v) => -1 !== contactMethod.indexOf(v.key))}
          label="Preferred Contact Method"
          options={options}
          optionsLabel="label"
          required={true}
          validationError={errors.contactMethod}
          onChange={(val) => {
            onChange(
              'contactMethod',
              (val as { key: ContactMethod; value: string }[]).map((v) => v.key)
            )
          }}
        />
      </div>
    </Container>
  )
}

export default ContactDetails
