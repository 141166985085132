import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import { MetroSpinner } from 'react-spinners-kit'

const ButtonComponent = styled.button`
  position: relative;
  border: 0;
  cursor: pointer;
  background-color: ${theme.cta.primary};
  color: white;
  border-radius: 0.5rem;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.13),
    inset 0 4px 6px 0 rgba(0, 0, 0, 0.06),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0) 23%,
    rgba(255, 255, 255, 0.2)
  );
  font-size: 0.75rem;
  font-weight: 500;
  text-shadow: 0 -0.5px 0 rgba(0, 0, 0, 0.13);
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.27),
      inset 0 -1px 0 0 rgba(0, 0, 0, 0.13),
      inset 0 1px 2px 0 rgba(255, 255, 255, 0.1);
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.18),
      rgba(255, 255, 255, 0.25)
    );
  }
  &:focus {
    outline: 0;
    box-shadow: 0px 0px 0.5px 3px rgba(121, 188, 29, 0.7),
      inset 0 -1px 0 0 rgba(0, 0, 0, 0.13),
      inset 0 4px 6px 0 rgba(0, 0, 0, 0.06),
      inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
    &:active {
      box-shadow: 0px 0px 0.5px 3px rgba(121, 188, 29, 0.7),
        inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    }
  }
  &:active {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.12),
      rgba(255, 255, 255, 0.06)
    );
  }
  &:disabled {
    cursor: not-allowed;
    box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.27),
      inset 0 1px 3px 0 rgba(0, 0, 0, 0.23);
    background-color: rgba(0, 0, 0, 0.06);
    color: rgba(112, 112, 112, 0.57);
    text-shadow: none;
  }
  &.small {
    padding: 4px 20px;
  }
  &.medium {
    padding: 9px 24px;
  }
  &.large {
    padding: 12px 24px;
    font-size: 0.875rem;
  }
  &.loading {
    color: rgba(0, 0, 0, 0);
    text-shadow: none;
  }
  .loader-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

interface Props {
  text: string
  size?: 'large' | 'medium' | 'small'
  type?: 'button' | 'submit'
  disabled?: boolean
  loading?: boolean
  onClick?: (event: React.MouseEvent) => void
}

const Button: React.FC<Props> = props => {
  const { size, text, type, disabled, loading, onClick } = props
  const buttonSize = size || 'medium'
  const buttonType = type || 'submit'

  return (
    <ButtonComponent
      className={`button ${buttonSize} ${loading && 'loading'}`}
      type={buttonType}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
      {loading && (
        <div className="loader-container">
          <MetroSpinner color={theme.background.default} size={22} />
        </div>
      )}
    </ButtonComponent>
  )
}

export default Button
