import React from 'react'
import styled from 'styled-components'

export const PrivacyPolicyContainer = styled.div`
  max-width: 1024px;
  margin: 20px auto 100px;
  padding: 0.5rem;
  table {
    border-collapse: collapse;
    td {
      border: 1px solid #ddd;
      padding: 8px;
    }
  }
`

const PrivacyPolicy: React.FC = () => {
  return (
    <PrivacyPolicyContainer>
      <div>
        <h1>
          Privacy Policy<br />
          Join HEINEKEN, HEINEKEN Direct and Star Pubs &amp; Bars Online
        </h1>
        <p>
          <strong>1. INTRODUCTION and IDENTIFYING the CONTROLLER of your personal data</strong>
        </p>
        <p>
          Heineken UK Limited and Star Pubs & Bars Limited are part of the Heineken Group.
        </p>
        <p>
          If you are viewing this policy in connection with your visit to the Join Heineken or
          Heineken Direct websites, Heineken UK  Limited  is  the  company  responsible  for
          processing  your  data  and  references  to  "<strong>we</strong>",  "<strong>us</strong>"
          or  "<strong>our</strong>" in this policy means Heineken UK Limited.
        </p>
        <p>
          If you are viewing this policy in connection with your visit to the Star Pubs & Bars Online
          website, Star Pubs & Bars Limited is the company responsible for processing your data and
          references to "<strong>we</strong>",  "<strong>us</strong>" or  "<strong>our</strong>"
          in this policy means Star Pubs & Bars Limited.
        </p>
        <p>
          If you have any questions about this privacy notice or our processing
          activities, we can be contacted as follows:
        </p>
        <p>
          &middot; <strong>Mail: </strong>3-4 Broadway Park, South Gyle
          Broadway, Edinburgh, EH12 9JZ, marked for the attention of the Privacy
          Officer; or
        </p>
        <p>
          &middot; <strong>Email</strong>: protectingyourdata@heineken.co.uk.
        </p>
        <p>
          It is important that you read this privacy notice together with our{' '}
          <a target="_blank" href="/cookiepolicy">Cookie Policy</a> and any terms of use that
          apply to the services or Website which are presented to you. This
          privacy notice supplements the other notices and is not intended to
          override them.
        </p>
        <p>
          <strong>2. HOW and WHAT personal data do we collect about you?</strong>
        </p>
        <p>
          Personal data is any information about an individual from which that person can be identified.
          We may receive your personal data directly from you or otherwise have access to it if the
          information is publicly available.
        </p>
        <p>
          This privacy policy describes how we look after your personal data collected when you engage
          with us including when (i) you visit our Join Heineken, Heineken Direct or Star Pubs & Bars Online
          websites; (ii) you purchase products or services from us; and (iii) we communicate with each other
          as part of our ongoing business relationship including where you contact us via our websites,
          email or telephone with an enquiry or complaint and where we send email or other
          communications to you (&ldquo;<strong>Engagement</strong>&rdquo;).
        </p>
        <p>
          We collect different categories of information which we have grouped together as follows:
        </p>
        <p>
          &middot; Identity Data – name, username, title, place and date of birth and personal characteristics
          including age and gender;
        </p>
        <p>
          &middot; Contact Data – billing address, delivery address, email address and telephone number;
        </p>
        <p>
          &middot; Financial and Transactional Data - bank account and card payment details, and details about
          payments as well as products and services purchased from us;
        </p>
        <p>
          &middot; Profile Data – preferences, feedback and survey responses;
        </p>
        <p>
          &middot; Technical and Usage Data – information about how you use our websites (including your IP
          address and details about the devices you use to access our website). Please review the cookie policy
          on the relevant website for further information on this;
        </p>
        <p>
          &middot; Marketing and Communications Data – preferences in receiving marketing and communications
          from us and information in terms of engagement with email communications; and
        </p>
        <p>
          &middot; Location Data – GPS-based location information from your use of our websites via your smartphone(s),
          tablet(s) or other devices.
        </p>
        <p>
          We also collect, use and share <strong>Anonymised Data</strong> such as statistical or demographic data which
          is <strong>not</strong> reasonably likely to reveal your identity (directly or indirectly). For example, we
          may receive aggregated usage data detailing the percentage of users accessing a specific website. If we
          combine or connect Anonymised Data with other data so that it <u>can</u> directly or indirectly identify you,
          the combined data is 'personal data' which will be used in accordance with this privacy policy.
        </p>
        <p>
          We do not knowingly:
        </p>
        <p>
          &middot; Process any <strong>Special Categories of personal data</strong> about you (this includes details about your race or
          ethnicity, religious or philosophical beliefs, sexual orientation, political opinions, trade union membership,
          information about your health or genetics and biometric data). Nor do we process any information about
          criminal convictions and offences; or
        </p>
        <p>
          &middot; Collect personal data relating to children. Further, we do not market our products or brands to
          anyone under the age of 18.
        </p>
        <p>
          <strong>3. WHY do we collect your personal data?</strong>
        </p>
        <p>
          We collect the above categories of personal data about you for the following purposes (more specifically
          described in Annex 1):
        </p>
        <p>
          &middot; To enable you to register your interest in and/or create an account on our websites;
        </p>
        <p>
          &middot; To complete our customer application process via the Join Heineken website;
        </p>
        <p>
          &middot; To communicate with you and improve our products and services;
        </p>
        <p>
          &middot; To administer our business and perform contracts with you;
        </p>
        <p>
          &middot; To maintain and optimise our websites;
        </p>
        <p>
          &middot; To market to you;
        </p>
        <p>
          &middot; To conduct surveys/market research;
        </p>
        <p>
          &middot; For analytical purposes including tracking how you respond to the emails we send you so that we
          can understand and improve our approach to marketing;
        </p>
        <p>
          &middot; To enable you to partake in a promotion and for prize fulfilment purposes;
        </p>
        <p>
          &middot; To protect our business, comply with our contractual or regulatory obligations and prevent or
          detect crime;
        </p>
        <p>
          &middot; To satisfy our legal and regulatory obligations and co-operate with regulators and government
          bodies; and
        </p>
        <p>
          &middot; To defend and exercise our legal rights, including in relation to managing actual and potential
          claims.
        </p>
        <p>
          <strong>4. What is our LAWFUL BASIS for collecting your personal data?</strong>
        </p>
        <p>
          Under data protection laws, we must have a lawful basis under which we process your personal data. We will
          only use your personal data for the purposes above, unless we reasonably consider that we have another
          appropriate reason and that reason is compatible with the original purpose. If we need to use your personal
          data for an unrelated purpose, we will notify you and we will explain the lawful basis which allows us to do
          so.
        </p>
        <p>
          If you provide us with your consent to processing in connection with your use of our websites, you can
          withdraw it at any time and we will stop the processing activities that were based on consent as a lawful
          basis. Please note we may still process the data if we have another lawful basis for processing (in most
          instances, this will be for a more limited purpose e.g. back-up storage or to record a withdrawal).
        </p>
        <p>
          Where we need to collect personal data due to a legal or regulatory obligation, or for performance of a
          contract, and you do not provide that data when requested, we may not be able to continue our Engagement
          with you or perform the contract we have or are trying to enter into with you (for example, to provide you
          with products or allow you to participate in competitions). We will notify you of this at the time.
        </p>
        <p>
          Further information on the relevant purposes and linked lawful basis are set out in Annex 1.
        </p>
        <p>
          <strong>5. WHO do we SHARE your personal data with?</strong>
        </p>
        <p>We may share your personal data with the parties set out below:</p>
        <ul>
          <li>
            <strong>Internal third parties</strong> - other companies in the Heineken group based within the EEA and
            the UK;
          </li>
          <li>
            <strong>External third parties</strong> - which include:
            <ul>
              <li>
                IT and system administration service providers (including data storage providers and data management
                platform providers);
              </li>
              <li>
                communications platform providers (i.e. vendors we use to send and manage email and SMS communications);
              </li>
              <li>
                service providers such as solicitors, accountants, insurance companies and insurance claims managers,
                debt recovery agencies, payment processing companies, logistics companies and our technical dispense
                equipment services provider;
              </li>
              <li>
                marketing and advertising companies and media agencies for marketing and research purposes, and to
                provide promotion services, data on-boarding services, research and marketing strategy services;
              </li>
              <li>
                prize fulfilment agencies;
              </li>
              <li>
                regulators, local authorities and government bodies, including the Police and HMRC, to comply with any
                legal or regulatory requirements or formal/informal investigations;
              </li>
              <li>
                courts, parties to litigation and professional advisers where we reasonably deem it necessary in
                connection with the establishment, exercise or defence of legal claims; and
              </li>
              <li>
                a purchaser or parties interested in purchasing any part of our business (and professional advisors
                supporting on the transaction).
              </li>
            </ul>
          </li>
        </ul>
        <p>
          We require all third parties to respect the security of your personal data and to treat it in accordance
          with the law. Where third parties act as processors on our behalf, we only permit them to process your
          personal data for specified purposes and in line with our instructions.
        </p>
        <p>
          <strong>6. International transfers</strong>
        </p>
        <p>
          Our external third parties may be based outside the UK or the EEA. Whenever we transfer your personal data
          out of the UK or the EEA, we ensure that the same level of protection is afforded to it by ensuring at
          least one of the following safeguards are put in place:
        </p>
        <ul>
          <li>
            We will only transfer your personal data to countries that have been deemed to provide an adequate level
            of protection for personal data by the European Commission and/or the UK Information Commissioner's
            Office. For further details, see
            &nbsp;<a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en">European Commission: Adequacy of
            the protection of personal data in non-EU countries</a> and
            &nbsp;<a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/international-data-transfer-agreement-and-guidance">ICO: International Data Transfer Agreement and Guidance</a>; and
          </li>
          <li>
            Where we use certain service providers, we may use specific contracts approved by the European Commission
            and/or the UK Information Commissioner's Office which give personal data the same protection it has in
            Europe (or the United Kingdom). For further details, see
            &nbsp;<a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en">European Commission: Model contracts for the
            transfer of personal data to third countries</a> and
            &nbsp;<a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/international-data-transfer-agreement-and-guidance">ICO: International Data Transfer Agreement and Guidance.</a>
          </li>
        </ul>
        <p>
          <strong>7. How SECURE is my data?</strong>
        </p>
        <p>
          We have put in place reasonable security measures to prevent your personal data from being accidentally
          lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your
          personal data to those employees, agents, contractors and other third parties who have a business need to
          know - they are subject to a duty of confidentiality. Unfortunately, no transmission of information over
          the internet can be completely secure, and the security of information depends in part on the security of
          the computer you use to communicate with us and the security you use to protect account information and
          passwords. Please, take care to protect this information.
        </p>
        <p>
          Our website and the Wi-Fi services include links to third-party websites, plug-ins and applications.
          Clicking on those links or enabling those connections may allow third parties to collect or share data
          about you. We do not control these third party websites, plug-ins or applications and are not responsible
          for their privacy statements. We encourage you to read the privacy policy of every website you visit and
          third party service/application that you use.
        </p>
        <p>
          <strong>8. How LONG will my personal data be used for?</strong>
        </p>
        <p>
          We will only retain your personal data to fulfil the purposes we collected it for, including for the
          purposes of satisfying any legal, tax, accounting, or reporting requirements. To determine the appropriate
          retention period for personal data, we consider any legal requirements, the amount, nature, and sensitivity
          of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data,
          the purposes for which we process your personal data and whether we can achieve those purposes through other
          means. Criteria used to determine retention periods for specific data collected are detailed further in
          Annex 1.
        </p>
        <p>
          <strong>9. What are my RIGHTS?</strong>
        </p>
        <p>
          Under data protection laws, you have various rights which are set out below. The rights available to you
          depend on our reason for processing your personal data. You are not required to pay any charge for exercising
          your rights, although we may charge a reasonable fee if your request is unfounded, repetitive or excessive.
          We have one month to respond to you (unless you have made a number of requests or your request is complex,
          in which case we may take up to an extra two months to respond). Please note that, where we ask you for
          proof of identification, the one-month time limit does not begin until we have received this. If we require
          any clarification and/or further information on the scope of the request, the one-month deadline is paused
          until we receive that information.
        </p>
        <table>
          <tbody>
          <tr>
            <td>
              <p>
                a) <strong>Right of access.</strong> You have the right to ask us for copies of your personal data.
                This right always applies. There are some exemptions, which means you may not always receive all the
                information we process.&nbsp;<a href="https://ico.org.uk/your-data-matters/your-right-to-get-copies-of-your-data/">You can read more about this right here</a>.
              </p>
              <p>
                b) <strong>Right to rectification.</strong> You have the right to ask us to rectify information you
                think is inaccurate. You also have the right to ask us to complete information you think is incomplete.
                This right always applies.
                &nbsp;<a href="https://ico.org.uk/your-data-matters/your-right-to-get-your-data-corrected/">You can read more about this right here.</a>.
              </p>
              <p>
                c) <strong>Right to erasure.</strong> You have the right to ask us to erase your personal data in
                certain circumstances.
                &nbsp;<a href="https://ico.org.uk/your-data-matters/your-right-to-get-your-data-deleted/">You can read more about this right here.</a>.
              </p>
              <p>
                d) <strong>Right to restriction of processing.</strong> You have the right to ask us to restrict the
                processing of your information in certain circumstances.
                &nbsp;<a href="https://ico.org.uk/your-data-matters/your-right-to-limit-how-organisations-use-your-data/">You can read more about this right here.</a>.
              </p>
              <p>
                e) <strong>Right to object to processing.</strong> You have the right to object to processing of your
                personal data where we are relying on a legitimate interest or conducting direct marketing
                &nbsp;<a href="https://ico.org.uk/your-data-matters/the-right-to-object-to-the-use-of-your-data/">You can read more about this right here.</a>.
              </p>
              <p>
                f) <strong>Right to withdraw consent.</strong> Where we are relying on consent to process your personal
                data, you may withdraw it at any time. This will not affect the lawfulness of any processing carried
                out before you withdraw your consent.
              </p>
              <p>
                g) <strong>Right to data portability.</strong> This only applies to information you have given us.
                You have the right to ask that we transfer the information you gave us from one organisation to
                another, or give it to you. The right only applies if we are processing information based on your
                consent
                &nbsp;<a href="https://ico.org.uk/your-data-matters/your-right-to-data-portability/">You can read more about this right here.</a>.
              </p>
            </td>
          </tr>
          </tbody>
        </table>
        <p>
          You also have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the
          UK supervisory authority for data protection issues:
        </p>
        <p>
          <strong>Information Commissioner’s Office</strong><br />
          Wycliffe House<br />
          Water Lane<br />
          Wilmslow<br />
          Cheshire<br />
          SK9 5AF<br />
          <strong>Helpline number: 0303 123 1113</strong><br />
          <strong>ICO website: <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></strong><br />
          We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please
          contact us in the first instance using the details at the start of this policy.
        </p>
        <p>
          This version was last updated in May 2022.
        </p>
        <p></p>
        <p>
          <strong>Annex 1 &ndash; Legal Basis for Processing</strong>
        </p>
        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Purpose/Activity</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Type of data</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>
                    Lawful basis for processing including basis of legitimate
                    interest
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>Retention period</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Where you <strong>create an account</strong> on our websites.</p>
              </td>
              <td>
                <p>Identity</p>
                <p>Contact</p>
              </td>
              <td>
                <p>Performance of a contract with you.</p>
              </td>
              <td>
                <p>
                  For the duration of your contract with us and for 2 years
                  after a period of inactivity.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To process and <strong>respond to your interest</strong> in becoming a Heineken customer or a
                  SmartDispense Customer or your application to add an additional outlet where you are already a
                  customer of ours through our Join Heineken website.
                </p>
              </td>
              <td>
                <p>Identity</p>
                <p>Contact</p>
              </td>
              <td>
                <p>Performance of a contract with you.</p>
              </td>
              <td>
                <p>
                  Information submitted through our Join Heineken website will be deleted 6 months after your
                  application is made.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Where we <strong>perform a contract we have in place with you</strong>, including managing payments,
                  fees and charges, and delivering the requested product or service.
                </p>
              </td>
              <td>
                <p>Contact</p>
                <p>Identity</p>
                <p>Financial and Transactional</p>
              </td>
              <td>
                <p>Performance of a contract with you;</p>
                <p>
                  To perform our legal obligations.
                </p>
              </td>
              <td>
                <p>
                  After the duration of your contract with us has expired, our online sales records will be retained
                  by us for 7 years or longer if required by tax or corporate bookkeeping. This data will be removed
                  after a period of 2 years of inactivity on your account.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To <strong>communicate with you and improve our products and services</strong>, which includes:
                </p>
                <ul>
                  <li>managing our relationship with you; investigating and responding to enquiries or complaints;</li>
                  <li>making suggestions to you about various products and services that may be of interest to you;</li>
                  <li>sending you emails, newsletters or alerts;</li>
                  <li>asking you to complete surveys about how we can improve the products or services we offer you; and</li>
                  <li>asking you for information on how we can improve our websites or our Engagements with you; and</li>
                  <li>notifying you about changes to our terms or privacy policy.</li>
                </ul>
              </td>
              <td>
                <p>Identity</p>
                <p>Contact</p>
                <p>Profile </p>
                <p>Technical and Usage </p>
                <p>Marketing and Communications</p>
              </td>
              <td>
                <p>
                  Performance of a contract with you.
                </p>
                <p>
                  Necessary for our legitimate interests (for running our business, in order to offer you a good
                  service and to protect our business).
                </p>
                <p>
                  Where required by privacy laws, consent.
                </p>
              </td>
              <td>
                <p>
                  Customer services will retain all information for 1 year after your question or complaint has been
                  solved, or the inquiry was closed.
                </p>
                <p>
                  If you no longer wish to receive marketing communications from us, you can unsubscribe at any time.
                  We will remove your email address once you have opted-out, unless this is also used and retained for
                  other purposes listed in this privacy notice.
                </p>
                <p>
                  Survey feedback will be retained until it has fulfilled its intended purpose (Note: please see
                  section 8 above “How LONG my personal data will be used for?” to learn more about the things we
                  consider when determining how long we will retain your personal data).
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To <strong>maintain and optimise our websites</strong> which includes where we need to solve
                  performance issues, including troubleshooting, testing, system maintenance, support, reporting and
                  hosting of data, to improve the availability and functionality of the websites.
                </p>
              </td>
              <td>
                <p>Identity </p>
                <p>Contact</p>
                <p>Profile</p>
                <p>Technical and Usage</p>
              </td>
              <td>
                <p>
                  Necessary for our legitimate interests to maintain the relevance of our brand and reputation, run
                  our business, operate administration and IT services, protect network security and to prevent fraud).
                </p>
                <p>
                  Necessary to comply with a legal obligation.
                </p>
              </td>
              <td>
                <p>
                  We retain information relating to the performance of our websites for 2 years.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To <strong>conduct data analytics</strong> to improve our websites, products/services, marketing
                  strategies and customer relationships, so that we can issue relevant marketing content and offers
                  and analyse email engagement. This includes:
                </p>
                <ul>
                  <li>
                    identifying what subjects are of most interest to you;
                  </li>
                </ul>
                <ul>
                  <li>
                    using tracking technologies to understand how you respond to our emails (for example, whether
                    you open the email, click-through links and/or unsubscribe); and
                  </li>
                </ul>
                <ul>
                  <li>
                    assessing the success of our marketing campaigns and communications.
                  </li>
                </ul>
                <p>
                  See Annex 2 for more information on our marketing and profiling activities.
                </p>
              </td>
              <td>
                <p>Identity </p>
                <p>Profile</p>
                <p>Marketing and Communications</p>
                <p>Technical and Usage</p>
                <p>Location</p>
              </td>
              <td>
                <p>
                  Necessary for our legitimate interests (to define types of customers for our products and services,
                  to keep our websites updated and relevant, to develop our business and to inform our marketing strategy).
                </p>
                <p>
                  Where required by privacy laws, consent.
                </p>
              </td>
              <td>
                <p>
                  Data will be processed until an opt-out / objection is received or consent is withdrawn as applicable.
                </p>
                <p>
                  The cookie policy on the relevant website provides more information on specific cookie retention
                  periods.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To <strong>administer and protect our business and our websites</strong> (including troubleshooting,
                  data analysis, testing, system maintenance, support, reporting and hosting of data).
                </p>
              </td>
              <td>
                <p>Identity</p>
                <p>Contact</p>
                <p>Technical and Usage</p>
              </td>
              <td>
                <p>
                  Necessary for our legitimate interests (for running our business, provision of administration and IT
                  services, network security, to prevent fraud and in the context of a business reorganisation or group
                  restructuring exercise);
                </p>
                <p>Necessary to comply with a legal obligation.</p>
              </td>
              <td>
                <p>26 months from website visit.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  To enable you to partake in <strong>promotions</strong> and for <strong>prize fulfilment</strong>
                  purposes, including:
                </p>
              </td>
              <td>
                <p>Identity</p>
                <p>Contact</p>
              </td>
              <td>
                <p>Performance of a contract with you;</p>
              </td>
              <td>
                <p>
                  6 months following prize fulfilment (in certain cases the retention period may be longer due to the
                  nature of the prize e.g. flight tickets – in such cases the personal data will be deleted when it
                  is no longer required).
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p></p>
        <p>
          <strong>Annex 2 &ndash; MARKETING and PROFILING in more detail</strong>
        </p>
        <p>
          <strong>Marketing</strong>
        </p>
        <p>
          We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.
        </p>
        <p>
          We will only send direct electronic marketing (e.g. via emails or SMS) where:
        </p>
        <ul>
          <li>
            you are a Heineken customer who has created an account on our Heineken Direct website or a Star Pubs &
            Bars Lessee who has created an account on our Star Pubs & Bars Online website and you did not opt-out of
            receiving marketing relating to our brand, as well as our comprehensive support packages, where this
            opportunity was provided to you;
          </li>
          <li>
            in relation to emails or other communications containing offers and discounts from carefully selected
            suppliers who are part of our Heineken or Star Pubs & Bars Buying Clubs, we have your consent; or
          </li>
          <li>
            you have solicited certain information (for example to receive one-off correspondence regarding products
            or services of ours).
          </li>
        </ul>
        <p>
          You can ask us to stop sending you direct marketing messages at any time by contacting us at
          &nbsp;<a href="mailto:unsubscribe@heineken.co.uk">unsubscribe@heineken.co.uk</a>. Where you opt out of
          receiving these marketing messages, we will no longer conduct direct electronic marketing unless you opt-in
          again at a later point. Please note that where we have another lawful basis for processing, we will continue
          to process personal data for other purposes – for example, we may process information provided to us in
          connection with an Engagement on the basis of contract necessity.
        </p>
        <p>
          <strong>Profiling</strong>
        </p>
        <p>
          We may use your Identity Data, Contact Data, Profile Data, Technical and Usage Data, Marketing and
          Communications Data and Location Data, to form a view on what we think you may want or what may be of
          interest to you and to understand your purchasing trends. These profiling activities inform how we decide
          which brands, products, services and offers may be relevant to you, and to send you tailored communications
          in order to make personalised recommendations, inform you of special offers we think you will be interested
          in and customise promotions & special offers that are most relevant to you. In more detail:
        </p>
        <ul>
          <li>
            when you receive an email from us, we may receive certain information about how you interact with that
            email. The information we collect includes the number of times you have opened the email; if you have
            clicked links in the email; whether you have unsubscribed or marked the email as spam; whether the email
            has bounced; whether you have shared the information on social media or forwarded it to friends. This
            ensures you do not receive irrelevant or unwanted emails, as well as allowing us to use our resources
            efficiently.
          </li>
        </ul>
        <p>
          Please note that whilst we carry out the profiling activities described here, we do not carry out any
          automated decision-making processes which could have a legal or significant impact on you.
        </p>
      </div>
    </PrivacyPolicyContainer>
  )
}

export default PrivacyPolicy
