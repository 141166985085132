import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import TickIcon from 'assets/icon/line@2x.png'

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.5rem;
  .checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  &.error {
    button {
      border: 1px solid ${theme.status.dangerLight};
      &:focus {
        box-shadow: 0 0 0.5px 2px ${theme.status.dangerLight},
          0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
      }
    }
  }
  &.disabled {
    label {
      color: ${theme.text.disabled};
    }
    button {
      box-shadow: inset 0 0 0 1px rgba(151, 151, 151, 0.09);
      opacity: 0.5;
    }
  }
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`

const CheckboxButton = styled.button`
  vertical-align: middle;
  background-color: ${theme.background.defaultCheckbox};
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.06);
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &.checked {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.cta.primary};
    padding: 0;
    & img {
      min-width: 10px;
      min-height: 9px;
      width: calc(100% - 8px);
      height: auto;
      object-fit: contain;
    }
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0.5px 3px rgba(121, 188, 29, 0.7),
      0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(121, 188, 29, 0.7);
  }
  &:active {
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.05),
      inset 0 0 0 1px rgba(151, 151, 151, 0.09);
    background-color: rgba(226, 234, 238, 0.77);
  }
  &:hover {
    box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.26);
    border: solid 1px rgba(0, 0, 0, 0.04);
    &:focus {
      outline: none;
      box-shadow: 0 0 0.5px 3px rgba(121, 188, 29, 0.7),
        0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(121, 188, 29, 0.7);
    }
  }
`

const ValidationError = styled.span`
  font-size: 0.75rem;
  color: ${theme.status.danger};
`

const Label = styled.label`
  vertical-align: middle;
  padding-left: 0.75rem;
  font-weight: normal;
  font-size: 0.75rem;
  color: ${theme.text.body};
  cursor: pointer;
  display: none;
  &.has-label {
    display: inline;
  }
`

interface Props {
  disabled?: boolean
  label?: string
  name?: string
  validationError?: string
  value: any
  size?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: React.FC<Props> = props => {
  const {
    disabled,
    label,
    name,
    validationError,
    value,
    onChange,
    size,
  } = props
  const ref = React.createRef<HTMLInputElement>()

  const defaultSize = '24px'

  return (
    <CheckboxContainer
      className={`checkbox ${validationError ? 'error' : ''} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={() => {
        ref.current && ref.current.click()
      }}
    >
      <div className="checkbox-wrapper">
        <CheckboxButton
          type="button"
          style={{ width: size || defaultSize, height: size || defaultSize }}
          className={`${value ? 'checked' : ''}`}
          disabled={disabled}
        >
          {value && <img src={TickIcon} alt="tick" />}
        </CheckboxButton>

        <Label
          className={`${label && 'has-label'}`}
          onClick={() => {
            ref.current && ref.current.click()
          }}
        >
          {label}
          <HiddenCheckbox
            disabled={disabled}
            name={name}
            value={value}
            onChange={onChange}
            ref={ref}
          />
        </Label>
      </div>

      {validationError && <ValidationError>{validationError}</ValidationError>}
    </CheckboxContainer>
  )
}

export default Checkbox
