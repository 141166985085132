import React from 'react'
import styled from 'styled-components'
import Supplier from 'models/entities/Supplier'
import Select from 'components/FormElements/Select'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .label-container {
    flex-basis: 40%;
    margin-top: 1rem;
    h4 {
      white-space: nowrap;
    }
    .section-heading {
      font-size: 12px;
      font-weight: 800;
      line-height: 1.75;
      color: #006e31;
      text-transform: uppercase;
    }
  }
  .select-container {
    flex-basis: 60%;
    min-width: 320px;
    margin: 1rem 0 1.75rem 0;
  }
`

interface Props {
  value: Supplier[]
  suppliers: Supplier[]
  onChange: (field: string, value: Supplier) => void
}

const CurrentSupplier: React.FC<Props> = props => {
  const { value, suppliers, onChange } = props
  return (
    <Container>
      <div className="label-container">
        <span className="section-heading">SECTION 2.</span>
        <h4 className="bold">Your Current Suppliers</h4>
      </div>
      <div className="select-container">
        <Select
          label="Select Your Current Suppliers"
          value={value}
          name="currentSupplier"
          placeholder="You can select multiple"
          multiselect={true}
          options={suppliers}
          optionsValue="id"
          optionsLabel="name"
          onChange={onChange}
        />
      </div>
    </Container>
  )
}

export default CurrentSupplier
