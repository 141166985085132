import React from 'react'
import styled from 'styled-components'

export const TermsOfUseContainer = styled.div`
  max-width: 1024px;
  margin: 20px auto 100px;
  padding: 0.5rem;
`

const TermsOfUse: React.FC = () => {
  return (
    <TermsOfUseContainer>
      <div>
        <p>
          &nbsp;<strong>TERMS OF USE</strong>
        </p>
        <p>
          <strong>
            Heineken UK Limited (&ldquo;Heineken&rdquo;, &ldquo;we&rdquo; or
            &ldquo;us&rdquo;) respects your privacy and recognises your desire
            to safeguard your personal information. These Terms of Use, along
            with our Privacy Policy and Cookies Policy, are designed to inform
            you about the kind of personal information collected on this website
            (the &ldquo;Website&rdquo;), to inform you of the conditions of use
            of the Website, and how we maintain the security of the Website.
            Please read the Terms of Use set out below carefully.
          </strong>
        </p>
        <p>
          By using our Website, you acknowledge and agree to the following Terms
          of Use.{' '}
          <strong>
            If you do not agree to these terms, please do not use this Website.{' '}
          </strong>
        </p>
        <p>
          1. <strong>Agreement to terms.</strong> These Terms of Use are
          applicable to the Website, unless explicitly stated otherwise in any
          particular instance. By entering this Website and indicating your
          acceptance, you acknowledge and agree that you shall be bound by any
          revisions to the Terms of Use. We suggest periodically visiting this
          page of the Website to review these Terms of Use for any changes.{' '}
        </p>
        <p></p>
        <p>
          2. <strong>Who we are. </strong>This Website is operated by Heineken
          UK Limited, a company registered in Scotland under company number
          (SC065527) with registered office at 3-4 Broadway Park, South Gyle
          Broadway, Edinburgh EH12 9JZ.{' '}
        </p>
        <p>
          3. <strong>No entry for under-aged persons:</strong> This Website is
          intended only for residents in the UK of at least 18 years of age. Do
          not use this Website if you are younger than 18 and/or not resident in
          the UK. Date of birth verification is required to gain access to the
          Website and prompts may be located within the Website.
        </p>
        <p>
          4. <strong>Collection of Personal Data. </strong>You acknowledge and
          agree that any personal data that you provide and/or we collect when
          you use this Website shall be processed in accordance with our{' '}
          <a href="/privacypolicy">Privacy Policy</a> and in accordance with
          relevant data protection laws which include, without limitation, GDPR
          as transposed into the United Kingdom national law by operation of
          section 3 of the European Union (Withdrawal) Act 2018 and as amended
          by the Privacy and Electronic Communications (Amendments etc.) (EU
          Exit) Regulations 2019, Data Protection Act 2018, Data Protection,
          Privacy and Electronic Communications (Amendments etc.) (EU Exit)
          Regulations 2019 and the EU General Data Protection Regulation
          2016/279.{' '}
        </p>
        <p>
          5. <strong>Location and Jurisdiction.</strong> This Website is
          controlled and operated by Heineken from its offices in the UK.
          Heineken makes no representation that material in the Website is
          appropriate or available for use in other locations. Those who choose
          to access this Website from other locations do so on their own
          initiative and are responsible for compliance with local laws if, and
          to the extent, that local laws are applicable. These Terms of Use and
          anything in the Website shall be governed by the laws of England and
          Wales.{' '}
        </p>
        <p>
          6. <strong>Accessing our Site. </strong>Access to our Website is
          permitted on a temporary basis, and we reserve the right to withdraw
          or amend the service we provide on our Website without notice. From
          time to time, we may restrict access to some parts of our Website, or
          our entire Website, even to users who have registered with us. We will
          not be liable if, for any reason, our Website is unavailable at any
          time or for any period. You are responsible for making all
          arrangements necessary for you to have access to our Website. You
          shall not interfere or attempt to interfere with the operation of the
          Website or the use thereof by other users, in any way through any
          means or device, including, but not limited to uploading computer
          viruses, logic/time bombs, Trojan horses, spamming, hacking, or any
          other means expressly prohibited by any provision of these Terms of
          Use.
        </p>
        <p></p>
        <p>
          7. <strong>Intellectual Property. </strong>This Website contains
          material which may include but not be limited to audio, graphics,
          sound and video recordings, charts, text, databases, information, or
          images of places or people and any names, copyright, registered
          designs, logos, trademarks and/or service marks (
          <strong>&ldquo;Materials</strong>&rdquo;) which are owned by or
          licensed to Heineken and protected by international copyright, trade
          mark and other intellectual property laws. By using this Website, you
          acknowledge and agree that any Materials on this Website, are owned or
          licensed by Heineken. You shall not be permitted to reproduce, modify,
          reverse engineer, distribute, exploit, decompile, create derivative
          works of (or based on) these Materials, or do any other act which is
          otherwise than for your own personal use in the UK or in respect of
          which Heineken has provided its express prior written consent.
          Heineken will enforce its intellectual property rights to the fullest
          extent of the law. Any unauthorised use of these Materials may subject
          you to penalties or liability for damages including, without
          limitation, those related to violation of trademarks, copyrights,
          privacy and passing off.{' '}
        </p>
        <p>
          8. <strong>Changes.</strong> Although the Materials and information on
          the Website are based on up-to-date information, and while Heineken
          makes all reasonable efforts to ensure that all content, information
          and Materials on this Website are correct, accuracy cannot be
          guaranteed and Heineken makes no warranties or representations of any
          kind as to its accuracy. If the need arises to update our Website, we
          may suspend access to our Website, or close it indefinitely.{' '}
        </p>
        <p>
          9. <strong>Linking to our Website. </strong>You may link to our home
          page only, provided you do so in a way that is fair and legal and does
          not damage our reputation or take advantage of it, but you must not
          establish a link in such a way as to suggest any form of association,
          approval or endorsement on our part where none exists. You must not
          establish a link from any website that is not owned by you. Our
          Website must not be framed on any other site. You must obtain our
          prior written permission to link any off-homepage pages to your
          website and we reserve the right to refuse permission at our sole
          discretion. We reserve the right to withdraw linking permission
          without notice.{' '}
        </p>
        <p>
          10. <strong>Disclaimer</strong>. Any use and/or browsing of this
          Website is performed at your own risk. This Website (including,
          without limitation, all information, content, materials and functions
          made available on or through this Website) is provided to you
          &ldquo;as is&rdquo; without further representation or warranty of any
          kind, either express or implied. To the fullest extent permissible by
          law with regard to the contents of this Website, Heineken explicitly
          disclaims and makes no representations or warranties of any kind
          whatsoever: (a) for the accuracy, merchantability, fitness for a
          particular purpose, title or non-infringement of any content published
          on or made available through this Website by any visitor to our site
          or by anyone who may be informed of any of its contents; or (b) that
          the server or means that you use to access the server that makes this
          Website available is free of viruses, bugs or other components that
          may infect, harm or cause damage to your computer equipment, network
          connections or any other property when you access, browse, download
          from or otherwise use this Website.{' '}
        </p>
        <p>
          11. <strong>Limitation of Liability. </strong>Under no circumstances,
          including but not limited to Heineken&rsquo;s negligence, will
          Heineken, Heineken&rsquo;s group companies or any third party involved
          in creating, producing, hosting or delivering this Website, be liable
          for: (a) any losses, costs or expenses of any kind (including, without
          limitation, legal fees, expert fees or other disbursements); or (b)
          any damages whatsoever, whether direct, indirect, incidental,
          consequential, special, punitive or of any other kind or nature;
          whether arising out of or through your access to, the use of or
          browsing of this Website, or through your downloading of any
          materials, including, without limitation, anything caused by any
          viruses, bugs, human action or inaction, failure or malfunction of any
          computer system, phone line, hardware or software programs, or any
          other errors, failures or delays in computer transmissions or network
          connections, or otherwise, even if Heineken has been advised of the
          possibility of such losses or damages or the same is considered
          reasonably foreseeable. This does not affect our liability for death
          or personal injury arising from our negligence, nor our liability for
          fraudulent misrepresentation, nor any other liability which cannot be
          excluded or limited under applicable law. Heineken further assumes no
          responsibility, and will not be liable for, any loss or corruption of
          data on account of your access to, use of, or browsing of the Website,
          or your downloading of any Materials from the Websites. In no event
          shall Heineken&rsquo;s total liability to you for all damages, losses
          and causes of action (whether arising in contract, tort or otherwise)
          exceed the amount paid by you, if any, for access to the Website.
        </p>
        <p>
          12. <strong>Indemnification.</strong> You agree to indemnify, defend
          and hold Heineken and each of its agents, directors, employees,
          information providers, licensors and licensees, officers, parents,
          subsidiaries and affiliates (collectively{' '}
          <strong>&ldquo;Indemnified Parties&rdquo;</strong>) harmless from and
          against any and all liability and costs (including, without
          limitation, legal fees and costs) incurred by the Indemnified Parties
          in connection with any claim arising out of any breach by you of these
          Terms of Use. You will cooperate as fully as reasonably required in
          Heineken&rsquo;s defence of any such claim. Heineken reserves the
          right, at its own expense, to assume the exclusive defence and control
          of any matter subject to indemnification by you. In no event shall you
          settle any such matter without the express prior written consent of
          Heineken.
        </p>
        <p>
          13. <strong>Reporting Intellectual Property.</strong> Heineken
          respects the intellectual property rights of others. It is our policy
          not to permit materials known by Heineken to infringe another
          party&rsquo;s copyright to remain on any portion of the Website. If
          you believe any materials on any part of the Website infringe a
          copyright, you should provide Heineken with written notice that at a
          minimum contains: (a) a physical or electronic signature of a person
          authorised to act on behalf of the owner of an exclusive right that is
          allegedly infringed; (b) identification of the copyrighted work
          claimed to have been infringed, or, if multiple copyrighted works at a
          single online site are covered by a single notification, a
          representative list of such works; (c) identification of the material
          claimed to be infringing or to be the subject of infringing activity
          that that is to be removed or access to which is to be disabled and
          information reasonably sufficient to permit Heineken to locate the
          material; (d) information reasonably sufficient to permit Heineken to
          contact you, such as an address, telephone number and, if available,
          an electronic mail address; (e) a statement that you have a good faith
          belief that use of the material in the manner complained of is not
          authorised by the copyright owner, its agent, or the law; and (f) a
          statement that the information in the notification is accurate, and
          under penalty of perjury, that you are authorised to act on behalf of
          the owner of an exclusive right that is allegedly infringed.{' '}
        </p>
        <p>
          14. <strong>General Provisions</strong>. These Terms of Use together
          with our Privacy Policy and Cookie Policy set forth the entire
          understanding and agreement between you and Heineken with respect to
          the Website and your use thereof. You agree that Heineken may transfer
          its rights and obligations under these Terms of Use to another person
          without consent. You acknowledge that any other agreements between you
          and Heineken with respect to the Website, if any, are superseded and
          of no force or effect. If any provision of these Terms of Use shall be
          deemed unlawful, void or unenforceable for any reason, then such
          provision will be deemed severable from these Terms of Use and shall
          not affect the validity and enforceability of any remaining
          provisions.
        </p>
        <p>
          <strong>
            These Terms of Use were last updated in February 2021.
          </strong>
        </p>
      </div>
    </TermsOfUseContainer>
  )
}

export default TermsOfUse
