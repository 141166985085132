import React from 'react'
import styled from 'styled-components'
import theme from 'theme'

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &.error {
    button {
      border: 1px solid ${theme.status.dangerLight};
      &:focus {
        box-shadow: 0 0 0.5px 2px ${theme.status.dangerLight},
          0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
      }
    }
  }
  &.disabled {
    label {
      color: ${theme.text.disabled};
    }
    button {
      box-shadow: inset 0 0 0 1px rgba(151, 151, 151, 0.09);
      opacity: 0.5;
    }
  }
  &.radio-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
`

const RadioBtn = styled.button`
  vertical-align: middle;
  border-radius: 100%;
  box-sizing: border-box;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.06);
  background-color: ${theme.background.defaultCheckbox};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &.checked {
    padding: 0;
    div {
      width: 50%;
      height: 50%;
    }
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0.5px 3px rgba(121, 188, 29, 0.7),
      0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(121, 188, 29, 0.7);
  }
  &:active {
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.05),
      inset 0 0 0 1px rgba(151, 151, 151, 0.09);
    background-color: rgba(226, 234, 238, 0.77);
  }
  &:hover {
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.26);
    border: solid 1px rgba(0, 0, 0, 0.04);
    &:focus {
      outline: none;
      box-shadow: 0 0 0.5px 3px rgba(121, 188, 29, 0.7),
        0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(121, 188, 29, 0.7);
    }
  }
`

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  display: none;
`

const Label = styled.label`
  vertical-align: middle;
  margin-left: 0.75rem;
  font-weight: normal;
  font-size: 0.75rem;
  color: ${theme.text.body};
  cursor: pointer;
`

const ValidationError = styled.span`
  font-size: 0.75rem;
  color: ${theme.status.danger};
`

const RadioIcon = styled.div`
  background-color: ${theme.background.defaultRadio};
  position: relative;
  border-radius: 100%;
  left: 25%;
`

interface Props {
  disabled?: boolean
  label?: string
  name?: string
  validationError?: string
  option: any
  value?: any
  size?: string
  onChange: (value: any) => void
}

const RadioButton: React.FC<Props> = props => {
  const {
    disabled,
    label,
    name,
    validationError,
    option,
    value,
    size,
    onChange,
  } = props
  const ref = React.createRef<HTMLInputElement>()

  const defaultSize = '26px'

  return (
    <RadioContainer
      className={`radio-container ${validationError ? 'error' : ''} ${
        disabled ? 'disabled' : ''
      } ${value === option ? 'selected' : ''}`}
      onClick={() => {
        onChange(null)
        ref.current && ref.current.click()
      }}
    >
      <div className="radio-btn-wrapper">
        <RadioBtn
          type="button"
          style={{ width: size || defaultSize, height: size || defaultSize }}
          className={`${value === option ? 'checked' : ''}`}
          disabled={disabled}
        >
          {value === option && <RadioIcon />}
        </RadioBtn>

        <Label>
          {label}
          <HiddenRadio
            disabled={disabled}
            checked={value === option}
            name={name}
            onChange={() => {
              onChange(option)
            }}
            ref={ref}
          />
        </Label>
      </div>

      {validationError && <ValidationError>{validationError}</ValidationError>}
    </RadioContainer>
  )
}

export default RadioButton
