import Axios from '../Client'
import InnovativeSolution from 'models/entities/InnovativeSolution'

export default class InnovativeSolutionService {
  public static async listInnovativeSolutions(): Promise<InnovativeSolution[]> {
    const response = await Axios.get<InnovativeSolution[]>(
      '/innovative-solutions'
    )
    return response.data
  }
}
