import React from 'react'
import styled from 'styled-components'

export const CookiePolicyContainer = styled.div`
  max-width: 1024px;
  margin: 20px auto 100px;
  padding: 0.5rem;
  .cookie-use {
    border-collapse: collapse;
    td {
      border: 1px solid #ddd;
      padding: 8px;
    }
  }
`

const CookiePolicy: React.FC = () => {
  return (
      <CookiePolicyContainer>
        <div>
          <h1>Cookie Policy</h1>
          <p>
            <strong>1. What this Policy Covers</strong>
          </p>
          <p>This Cookie Policy sets out:</p>
          <ul>
            <li>what a cookie is (paragraph 2);</li>
            <li>an overview of the cookies we use (paragraph 3);</li>
            <li>
              what you can do if you do not agree to the cookie use (paragraph 4);
            </li>
            <li>concluding remarks (paragraph 5); and</li>
            <li>
              a detailed table setting out all of the cookies that we use (Annex
              1).
            </li>
          </ul>
          <p>
            We use cookies to give you a better online experience. If you have
            already indicated your cookie preferences in relation to this website
            (&ldquo;Website&rdquo;), you should still read the following policy to
            ensure you understand how we use cookies.
          </p>
          <p>
            In order to make full use of the Website, your computer, tablet or
            mobile phone will need to accept cookies. Accordingly, it is important
            that you know what cookies our Website uses and for what purposes.
          </p>
          <p></p>
          <p>
            <strong>2. What is a cookie?</strong>
          </p>
          <p>
            Cookies are small text files containing small amounts of information
            which are downloaded and may be stored on your user device e.g. your
            computer (or other internet enabled devices, such as a smartphone or
            tablet). We may use similar techniques, such as pixels, GIFs, web beacons,
            device fingerprints, etc. For the sake of consistency, all these
            techniques combined will hereafter be referred to as ‘cookies’.
          </p>
          <p>
            This Cookie Policy provides you with information about the cookies we
            use and why. Sometimes a cookie ID or the information a cookie collects
            can be considered personal data. Our <a href="/privacypolicy">Privacy Policy</a>
            sets out full details of the personal data we may collect and how we may use
            your personal information.
          </p>
          <p>
            <strong>3. Cookies used on the Website</strong>
          </p>
          <p>
            We use functional cookies to operate the Website (these are set by default).
            We’d also like to set optional analytical cookies. By clicking “YES” or “Accept”
            on the cookie banner, you give your consent to us placing and/or reading cookies.
          </p>
          <p>
            Some of these are session cookies that are temporary and allow us to link your
            actions during a browser session. Session cookies are erased when you close the
            browser. Other cookies are persistent cookies, which remain on your device for the
            period of time specified in the cookie. This Website uses cookies for the
            following purposes:
          </p>
          <ul>
            <li>
              <p>
                <strong>FUNCTIONAL COOKIES</strong>
              </p>
              <p>
                These  cookies  are  essential  and  help  you  navigate  the  Website.  They
                enhance  the  user  friendliness  of  the Website. These cookies make sure that
                you only have to pass the age-gate once and that your basket is saved during all
                the steps of your checkout process. These cookies also help to support website
                security and basic functionality.
              </p>
            </li>
            <li>
              <p>
                <strong>ANALYTICAL COOKIES</strong>
              </p>
              <p>
                Analytical cookies help us to understand the behaviour of our visitors and their usage
                of the Website in an aggregated manner.  For  instance,  we  can  use  these  cookies
                to gain  insight  into  how  our  visitors  use  our Website. This means we can find
                out what works and what does not. It allows us to continuously improve the  Website
                and to measure  how  effective our advertising and communication is.
                Analytical  cookies also allow the  Website to remember choices  made (such as
                language or the region you are in) and provide enhanced, more personal features.
                An example might be that our Website recognises if the user has already signed up for
                our newsletter, or if the user is seeing a certain page for the first time.
              </p>
              <p>
                We also use technologies (with your consent) to track email engagement data (i.e. opens
                and clicks to understand when an email is opened, how many times it is opened, what
                device or devices are involved and rough physical location, deduced from the internet
                protocol (IP) address). Based on this information, we will adapt our marketing campaigns
                and communications to ensure our emails are relevant and of interest to you.
              </p>
            </li>
          </ul>
          <p>
            <strong>4. Control your cookie settings</strong>
          </p>
          <p>
            You have the option to consent to our use of the different categories
            of cookies by clicking ‘Set Preferences’ in the cookie banner that is
            presented to you when you visit our Website. You can change your
            cookie preferences at any time by navigating to the cookie preferences
            centre in the footer of the Website – which also enables you to
            withdraw the consent you have previously given to us. The cookie
            preferences centre includes a list of all performance/analytical and
            advertising cookies.
          </p>
          <p>
            Besides using the ‘Set Preferences’ hyperlink on our Website, you can
            manage your cookie settings in your browser at any time. Please be
            aware that your browser settings may not offer you the same ease of
            use as the cookie preferences centre on our Website. If you simply
            disable all cookies in your browsers settings, you may find that
            certain sections or features of our Website will not work, because
            your browser may prevent us from setting functionally required
            cookies. Therefore, it is recommended to use the cookie preferences
            centre on our Website instead of disabling all cookies via your web
            browser. The following links may assist you in managing your cookies
            settings, or you can use the 'Help' option in your internet browser
            for more details.
          </p>
          <ul>
            <li>
              Internet Explorer:{' '}
              <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
                https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
              </a> /
              <a href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
                https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd
              </a>
            </li>
            <li>
              Mozilla Firefox:{' '}
              <a href="http://support.mozilla.com/en-US/kb/Cookies">
                http://support.mozilla.com/en-US/kb/Cookies
              </a>
            </li>
            <li>
              Google Chrome:{' '}
              <a href="http://www.google.com/support/chrome/bin/answer.py?hl=en&amp;answer=95647">
                http://www.google.com/support/chrome/bin/answer.py?hl=en&amp;answer=95647
              </a>
            </li>
            <li>
              Safari:{' '}
              <a href="http://support.apple.com/kb/PH5042">
                http://support.apple.com/kb/PH5042
              </a>
            </li>
            <li>
              Opera:{' '}
              <a href="https://help.opera.com/en/latest/web-preferences/">
                https://help.opera.com/en/latest/web-preferences/
              </a>
            </li>
          </ul>
          <p>
            Please be aware that we do not currently make use of a technical solution
            that would enable us to respond to your browser’s ‘Do Not Track’ signals.
          </p>
          <p>
            <strong>5. Concluding remarks</strong>
          </p>
          <p>
            We will keep this Cookie Policy under review and may make updates to
            it from time to time. Any changes to this Cookie Policy will be posted
            on our Website, and to the extent reasonably possible, will be
            communicated to you. You can consult this web page for the latest
            version.
          </p>
          <p>
            If you have any further questions and/or comments, please contact{' '}
            <a href="mailto:protectingyourdata@heineken.co.uk">
              protectingyourdata@heineken.co.uk
            </a>
          </p>
          <p>
            The most recent version of this Cookie Policy is dated May 2022.
          </p>
          <p>
            <strong>6. ANNEX 1 – Cookie Use</strong>
          </p>
          <p>Below is a full list of the cookies used on this Website:</p>
          <p>
            <strong>6.1 Functional cookies</strong>
          </p>
          <table className="cookie-use">
            <tbody>
            <tr>
              <td>
                <p>
                  &nbsp;<strong>NAME</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>PERSISTENCY</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>DESCRIPTION</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>SOURCE</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>HEINEKEN_ENSIGHTEN_PRIVACY_version</p>
              </td>
              <td>
                <p>Session</p>
              </td>
              <td>
                <p>Stores the cookie consent version</p>
              </td>
              <td>
                <p>Heineken.com</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>HEINEKEN_ENSIGHTEN_PRIVACY_MODAL_VIEWED</p>
              </td>
              <td>
                <p>1 Year</p>
              </td>
              <td>
                <p>Stores is the cookie modal is viewed</p>
              </td>
              <td>
                <p>Heineken.com</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>HEINEKEN_ENSIGHTEN_PRIVACY_Advertising</p>
              </td>
              <td>
                <p>1 Year</p>
              </td>
              <td>
                <p>
                  Stores the decision of the consumer on cookie advertisement
                  usage
                </p>
              </td>
              <td>
                <p>Heineken.com</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>HEINEKEN_ENSIGHTEN_PRIVACY_Analytics</p>
              </td>
              <td>
                <p>1 Year</p>
              </td>
              <td>
                <p>
                  Stores the decision of the consumer on cookie analytics usage
                </p>
              </td>
              <td>
                <p>Heineken.com</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>HEINEKEN_ENSIGHTEN_PRIVACY_BANNER_VIEWED</p>
              </td>
              <td>
                <p>1 Year</p>
              </td>
              <td>
                <p>Stores if the cookie banner is viewed</p>
              </td>
              <td>
                <p>Heineken.com</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>6.2 Analytical cookies</strong>
        </p>
        <table className="cookie-use">
          <tbody>
            <tr>
              <td>
                <p>
                  &nbsp;<strong>NAME</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>PERSISTENCY</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>DESCRIPTION</strong>
                </p>
              </td>
              <td>
                <p>
                  <strong>SOURCE</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_ga</p>
              </td>
              <td>
                <p>2 years</p>
              </td>
              <td>
                <p>
                  These cookies are used to identify a users and build a user
                  profile, and to manage all tags being enabled.
                </p>
              </td>
              <td>
                <p>Google</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_gid</p>
              </td>
              <td>
                <p>24 hours</p>
              </td>
              <td>
                <p>
                  Unique User Identifier used by Google Analytics to provide
                  insights on our site.
                </p>
              </td>
              <td>
                <p>Google</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_hjid</p>
              </td>
              <td>
                <p>1 Year</p>
              </td>
              <td>
                <p>This is for analytics and heat mapping</p>
              </td>
              <td>
                <p>Hotjar</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>_hjlncludeinSample</p>
              </td>
              <td>
                <p>2 Years</p>
              </td>
              <td>
                <p>
                  This session cookie is set to let Hotjar know whether that
                  visitor is included in the sample which is used to generate
                  funnels.
                </p>
              </td>
              <td>
                <p>Hotjar</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </CookiePolicyContainer>
  )
}

export default CookiePolicy
