import React from 'react'
import styled from 'styled-components'
import Brand from 'models/entities/Brand'
import theme from 'theme'
import Checkbox from 'components/FormElements/Checkbox'

const Container = styled.div`
  .cards {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    .card-container {
      display: flex;
      flex-basis: calc(20% - 8px);
      margin: 4px;
      min-width: 150px;
      .active {
        background-color: ${theme.background.defaultSelected};
      }
    }
  }

  @media screen and (min-width: 651px) and (max-width: 800px) {
    .cards {
      .card-container {
        flex-basis: calc(25% - 8px);
      }
    }
  }

  @media screen and (min-width: 501px) and (max-width: 650px) {
    .cards {
      .card-container {
        flex-basis: calc(33.33333% - 8px);
      }
    }
  }

  @media screen and (max-width: 500px) {
    .cards {
      .card-container {
        flex-basis: calc(50% - 8px);
      }
    }
  }
`

const BrandCard = styled.div`
  cursor: pointer;
  display: flex;
  background-color: ${theme.background.defaultAlt};
  width: 100%;
  padding-bottom: 66%;
  border-radius: 0.5rem;
  height: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 125%;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${theme.background.default};
    box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.4);
  }
  .checkbox {
    position: absolute;
    right: 0;
  }
`

interface Props {
  value: Brand[]
  brands: Brand[]
  onChange: (value: Brand[]) => void
}

const BrandSelection: React.FC<Props> = props => {
  const { value, brands, onChange } = props

  const setVal = (val: Brand) => {
    const index = value.indexOf(val)
    index !== -1
      ? onChange(value.filter((v, i) => i !== index))
      : onChange(value.concat([val]))
  }

  return (
    <Container className="brand-selection">
      <div className="cards">
        {brands.map((b, i) => {
          const { icon } = b
          return (
            <div className="card-container" key={i}>
              <BrandCard
                className={`brand-card ${value.indexOf(b) !== -1 && 'active'}`}
                style={{ backgroundImage: `url(${icon})` }}
                onClick={() => {
                  setVal(b)
                }}
              >
                <Checkbox
                  value={value.indexOf(b) !== -1}
                  onChange={() => {
                    setVal(b)
                  }}
                />
              </BrandCard>
            </div>
          )
        })}
      </div>
    </Container>
  )
}

export default BrandSelection
