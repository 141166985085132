import React from 'react'
import styled from 'styled-components'
import TagManager from 'react-gtm-module'
import NewEnquiry from 'pages/NewEnquiry'
import SplashScreen from 'pages/SplashScreen'
import FullScreenLoader from 'components/FullScreenLoader'
import ErrorScreen from 'components/ErrorScreen'
import GlobalContext from 'contexts/GlobalContext'
import CookiePolicy from 'pages/CookiePolicy'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import TermsOfUse from 'pages/TermsOfUse'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID!,
}

TagManager.initialize(tagManagerArgs)

const AppContainer = styled.div`
  .example-appear {
    opacity: 0.01;
  }

  .example-appear.example-appear-active {
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
`

const App: React.FC = () => {
  const { error, loading, loadGlobalContextData } = GlobalContext.Consumer()
  return loading ? (
    <FullScreenLoader />
  ) : error ? (
    <ErrorScreen
      onRetry={async () => {
        loadGlobalContextData()
      }}
    />
  ) : (
    <AppContainer>
      <ReactCSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={200}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Router>
          <Switch>
            <Route path="/" exact component={NewEnquiry} />
            <Route path="/smartdispense" exact component={NewEnquiry} />
            <Route path="/cookiepolicy" exact component={CookiePolicy} />
            <Route path="/privacypolicy" exact component={PrivacyPolicy} />
            <Route path="/termsofuse" exact component={TermsOfUse} />
            <Route path="/success">
              <SplashScreen />
            </Route>
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </ReactCSSTransitionGroup>
    </AppContainer>
  )
}

export default App
