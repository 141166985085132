import React from 'react'
import Anchor from 'components/Anchor'
import BeerHeineken from 'assets/icon/beer-heineken@2x.png'
import HeinekenLogo from 'assets/icon/logo-over-dark@2x.png'
import TickShape from 'assets/icon/shape@2x.png'
import { SplashScreenContainer } from './style'
import { Footer } from './style'

// FIXME: don't call JS from inside href
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint no-script-url: 0 */

interface Props {}

const SplashScreen: React.FC<Props> = props => {
  return (
    <SplashScreenContainer className="splash-screen-container">
      <div className="content-container">
        <div className="text-container">
          <div className="tick-headings-wrapper">
            <img src={TickShape} alt="tick-shape" className="tick-shape" />
            <div className="headings">
              <h1>Great! Thanks for your enquiry.</h1>

              <h4>We'll be in touch soon.</h4>
              <p>
                For more information about HEINEKEN and our ciders and beers
                please visit our website.
              </p>
              <div className="anchor-wrapper">
                <Anchor
                  href={process.env.REACT_APP_HEINEKEN_URL}
                  text="Take Me There"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="heinken-beer-container"
          style={{ backgroundImage: `url(${BeerHeineken})` }}
        ></div>
      </div>
      <Footer className="footer-container">
        <div className="footer-inner-container">
          <div className="links-wrapper">
            <div className="heineken-logo-wrapper">
              <img alt="heineken-logo" src={HeinekenLogo} />
            </div>
            <div className="cookie-url">
              <a
                href={process.env.REACT_APP_TERMS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              &middot;{' '}
              <a
                href={process.env.REACT_APP_HEINEKEN_COOKIE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </a>{' '}
              &middot;{' '}
              <a
                href={process.env.REACT_APP_HEINEKEN_TERMS_OF_USE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>{' '}
              &middot;{' '}
              <a href="javascript:Bootstrapper.gateway.openModal();">
                Cookie Settings
              </a>{' '}
              &middot;
              <a
                href={process.env.REACT_APP_HEINEKEN_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                www.heineken.co.uk
              </a>
            </div>
          </div>

          <div className="empty"></div>
        </div>
        <div className="footer-inner-container">
          <p className="colophon">
            &copy; Heineken UK Limited, 2020. All rights reserved. Heineken UK
            Limited. <br />
            Registered in Scotland. Registered company number: SC065527. <br />
            Registered VAT number: 268644912. Registered office: 3-4 Broadway
            Park, South Gyle Broadway, Edinburgh EH12 9JZ
          </p>
          <div className="empty"></div>
        </div>
      </Footer>
    </SplashScreenContainer>
  )
}

export default SplashScreen
