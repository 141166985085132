import React, { useState } from 'react'
import { Formik, FormikProps, FormikErrors } from 'formik'
import { Redirect } from 'react-router-dom'
import { showToast } from 'components/Toast'
import * as Yup from 'yup'
import OutletDetails from 'components/NewEnquiry/OutletDetails'
import BusinessTypes from 'components/NewEnquiry/BusinessTypes'
import FindOuts from 'components/NewEnquiry/FindOuts'
import ContactDetails from 'components/NewEnquiry/ContactDetails'
import GlobalContext from 'contexts/GlobalContext'
import Button from 'components/Button'
import { POSTCODE_REGEX } from 'components/PostcodeLookup/PostcodeLookup'
import Lang from 'lang'
import SDEnquiryService from 'services/EnquiryService/SDEnquiryService'
import Region from 'models/entities/Region'
import BusinessType from 'models/entities/BusinessType'
import FindOut from 'models/entities/FindOut'
import HeinekenCustomer from 'models/entities/HeinekenCustomer'
import ContactMethod from 'models/entities/ContactMethod'
import { Section } from './style'
import CustomerDetails from 'components/NewEnquiry/NewCustomer'
import CurrentHeinekenCustomer from 'components/NewEnquiry/CurrentHeinekenCustomer'
import { UK_PHONE_REGEX } from 'utils/ValidationHelper'

interface FormValues {
  newCustomer: boolean | null
  soldTo: string
  moreOutlets: boolean
  outletName: string
  outletPhone: string
  postcode: string
  addressLine1: string
  addressLine2: string
  addressLine3: string
  town: string
  region: Region | null
  businessTypes: BusinessType[]
  other: boolean
  otherValue: string
  findOut: FindOut | null
  otherFindOut: string
  otherFindOutRequired: boolean
  firstName: string
  lastName: string
  email: string
  mobile: string
  businessPhone: string
  contactMethod: ContactMethod[]
  terms: boolean
  heinekenCustomer: HeinekenCustomer | null
  heinekenCustomerOther: string
  otherCustomer: boolean
}

const FormEffect = (props: {
  formik: FormikProps<FormValues>
  onValidationError: (formik: FormikProps<FormValues>) => void
}) => {
  const { onValidationError, formik } = props

  const effect = () => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      onValidationError(formik)
    }
  }
  React.useEffect(effect, [formik.submitCount, formik.isSubmitting])

  return null
}

const formMap = {
  newCustomer: 'newCustomer',
  soldTo: 'soldTo',
  moreOutlets: 'moreOutlets',
  outletName: 'outlet.name',
  outletPhone: 'outlet.phone',
  postcode: 'outlet.postCode',
  addressLine1: 'outlet.line1',
  addressLine2: 'outlet.line2',
  addressLine3: 'outlet.line3',
  town: 'outlet.town',
  region: 'outlet.regionId',
  otherValue: 'outlet.otherBusinessType',
  firstName: 'primaryContact.name',
  lastName: 'primaryContact.lastName',
  email: 'primaryContact.email',
  mobile: 'primaryContact.mobile',
  businessPhone: 'primaryContact.landline',
  contactMethod: 'primaryContact.contactMethod',
  heinekenCustomer: 'heinekenCustomer',
  heinekenCustomerOther: 'heinekenCustomerOther',
}

const formatValidationErrors = (
  validationErrors: { field: string; message: string }[]
): FormikErrors<FormValues> => {
  return validationErrors.reduce((acc, error) => {
    const field = Object.keys(formMap).find(
      (k) => (formMap as any)[k] === error.field
    )
    field && ((acc as any)[field] = error.message)
    return acc
  }, {})
}

const NewSDEnquiryForm: React.FC = () => {
  const {
    regions,
    businessTypes,
    findOuts,
    heinekenCustomers,
  } = GlobalContext.Consumer()
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  return (
    <>
      {success && <Redirect to="/success" />}

      <Formik
        initialValues={{
          newCustomer: null,
          soldTo: '',
          moreOutlets: false,
          outletName: '',
          outletPhone: '',
          postcode: '',
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          town: '',
          region: null,
          businessTypes: [],
          other: false,
          otherValue: '',
          findOut: null,
          otherFindOut: '',
          otherFindOutRequired: false,
          firstName: '',
          lastName: '',
          email: '',
          mobile: '',
          businessPhone: '',
          contactMethod: [],
          terms: false,
          heinekenCustomer: null,
          otherCustomer: false,
          heinekenCustomerOther: '',
        }}
        validateOnChange={hasSubmitted}
        onSubmit={async (values: FormValues, actions) => {
          try {
            setLoading(true)
            await SDEnquiryService.createSDEnquiry({
              currentCustomer:
                values.newCustomer !== null ? !values.newCustomer : false,
              soldTo: values.soldTo,
              moreOutlets: values.moreOutlets,
              heinekenCustomer: values.heinekenCustomer
                ? values.heinekenCustomer.id
                : null,
              heinekenCustomerOther:
                values.heinekenCustomer &&
                values.heinekenCustomer.name === 'No – other'
                  ? values.heinekenCustomerOther
                  : '',
              outlet: {
                name: values.outletName,
                phone: values.outletPhone,
                businessTypeIds: values.businessTypes.map((b) => b.id),
                otherBusinessType: values.otherValue,
                line1: values.addressLine1,
                line2: values.addressLine2,
                line3: values.addressLine3,
                town: values.town,
                regionId: values.region ? values.region.id : null,
                postCode: values.postcode,
                howDidFindOut: values.findOut ? values.findOut.id : null,
                otherFindOut:
                  values.findOut && values.findOut.name === 'Other'
                    ? values.otherFindOut
                    : '',
              },
              primaryContact: {
                name: values.firstName,
                lastName: values.lastName,
                email: values.email,
                mobile: values.mobile,
                landline: values.businessPhone,
                contactMethod: values.contactMethod.map(
                  (cm) => ContactMethod[cm]
                ),
              },
            })
            setSuccess(true)
          } catch (error) {
            const { response } = error
            if (response && response.status === 400) {
              const validationErrors = formatValidationErrors(
                response.data.validationErrors
              )
              actions.setErrors(validationErrors)
              showToast.error('You have validation errors!')
            } else {
              showToast.error('An error has occurred, please try again.')
            }
          } finally {
            setLoading(false)
          }
        }}
        validationSchema={Yup.object().shape({
          newCustomer: Yup.boolean()
            .nullable()
            .required(Lang.validation.required),
          soldTo: Yup.string().when('newCustomer', {
            is: false,
            then: Yup.string().required(Lang.validation.required),
          }),
          moreOutlets: Yup.boolean().nullable(),
          outletName: Yup.string().required(Lang.validation.required),
          outletPhone: Yup.string()
            .required(Lang.validation.required)
            .matches(UK_PHONE_REGEX, Lang.validation.phone),
          postcode: Yup.string()
            .required(Lang.validation.required)
            .matches(POSTCODE_REGEX, Lang.validation.postcode),
          addressLine1: Yup.string().required(Lang.validation.required),
          town: Yup.string().required(Lang.validation.required),
          otherValue: Yup.string().when('other', {
            is: true,
            then: Yup.string().required(Lang.validation.required),
          }),
          firstName: Yup.string().required(Lang.validation.required),
          lastName: Yup.string().required(Lang.validation.required),
          email: Yup.string()
            .required(Lang.validation.required)
            .email(Lang.validation.email),
          mobile: Yup.string()
            .required(Lang.validation.required)
            .matches(UK_PHONE_REGEX, Lang.validation.phone),
          businessPhone: Yup.string()
            .required(Lang.validation.required)
            .matches(UK_PHONE_REGEX, Lang.validation.phone),
          contactMethod: Yup.array().required(Lang.validation.multiselect),
          otherFindOut: Yup.string().when('otherFindOutRequired', {
            is: true,
            then: Yup.string().required(Lang.validation.required),
          }),
          heinekenCustomer: Yup.object()
            .nullable()
            .required(Lang.validation.required),
          heinekenCustomerOther: Yup.string().when('otherCustomer', {
            is: true,
            then: Yup.string().required(Lang.validation.required),
          }),
        })}
      >
        {(props: FormikProps<FormValues>) => {
          const { handleSubmit, setFieldValue, values, errors } = props

          return (
            <form
              onSubmit={(event) => {
                event.preventDefault()
                setHasSubmitted(true)
                handleSubmit()
              }}
            >
              <FormEffect
                formik={props}
                onValidationError={() => {
                  showToast.error('You have validation errors!')
                }}
              />
              <>
                <CustomerDetails
                  values={{
                    newCustomer: values.newCustomer,
                    moreOutlets: values.moreOutlets,
                    soldTo: values.soldTo,
                  }}
                  errors={{
                    newCustomer: errors.newCustomer,
                    moreOutlets: errors.moreOutlets,
                    soldTo: errors.soldTo,
                  }}
                  onChange={setFieldValue}
                />
                <Section>
                  <OutletDetails
                    onChange={setFieldValue}
                    regions={regions}
                    values={{
                      outletName: values.outletName,
                      outletPhone: values.outletPhone,
                      postcode: values.postcode,
                      addressLine1: values.addressLine1,
                      addressLine2: values.addressLine2,
                      addressLine3: values.addressLine3,
                      town: values.town,
                      region: values.region,
                    }}
                    errors={{
                      outletName: errors.outletName,
                      outletPhone: errors.outletPhone,
                      postcode: errors.postcode,
                      addressLine1: errors.addressLine1,
                      town: errors.town,
                    }}
                  />
                </Section>
                <Section>
                  <CurrentHeinekenCustomer
                    values={{
                      heinekenCustomer: values.heinekenCustomer,
                      heinekenCustomerOther: values.heinekenCustomerOther,
                      otherCustomer: values.otherCustomer,
                    }}
                    onChange={setFieldValue}
                    heinekenSupplier={heinekenCustomers}
                    errors={{
                      heinekenCustomer: errors.heinekenCustomer,
                      heinekenCustomerOther: errors.heinekenCustomerOther,
                    }}
                  />
                </Section>
                <Section>
                  <BusinessTypes
                    values={{
                      selectedBusinessTypes: values.businessTypes,
                      other: values.other,
                      otherValue: values.otherValue,
                    }}
                    error={errors.otherValue}
                    onChange={setFieldValue}
                    businessTypes={businessTypes}
                    sectionNumber="3"
                  />
                </Section>
                <Section>
                  <FindOuts
                    sectionTitle="Where did you hear about Heineken SmartDispense™"
                    selectLabel="Select where you heard about Heineken SmartDispense™"
                    inputLabel="Other way you heard about Heineken SmartDispense™ *"
                    values={{
                      findOutSelected: values.findOut,
                      otherFindOut: values.otherFindOut,
                      otherFindOutRequired: values.otherFindOutRequired
                    }}
                    onChange={setFieldValue}
                    findOuts={findOuts}
                    sectionNumber="4"
                    errors={{
                      otherFindOut: errors.otherFindOut,
                    }}
                  />
                </Section>
                <Section>
                  <ContactDetails
                    values={{
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      mobile: values.mobile,
                      businessPhone: values.businessPhone,
                      contactMethod: values.contactMethod,
                    }}
                    errors={{
                      firstName: errors.firstName,
                      lastName: errors.lastName,
                      email: errors.email,
                      mobile: errors.mobile,
                      businessPhone: errors.businessPhone,
                      contactMethod: errors.contactMethod as string | undefined,
                    }}
                    onChange={setFieldValue}
                    sectionNumber="5"
                  />
                </Section>
                <div className="submit-container">
                  <span className="additional-text">
                    For more information on how Heineken UK Limited handle your
                    personal data please see our{' '}
                    <a
                      href="/privacypolicy"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </span>
                  <Button
                    text="Submit"
                    type="submit"
                    size="large"
                    loading={loading}
                  />
                </div>
              </>
            </form>
          )
        }}
      </Formik>
    </>
  )
}

export default NewSDEnquiryForm
