import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import { Link } from 'react-router-dom'

const CardButton = styled.button`
  display: flex;
  flex-direction: column;
  max-width: 345px;
  box-sizing: border-box;
  min-height: 160px;
  padding: 1.5em;
  border-radius: 9px;
  box-shadow: 9px 10px 16px 0px rgba(0, 0, 0, 0.13);
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  .content-card {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      text-align: left;
      text-transform: uppercase;
      color: #a7d985;
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 1px;
    }
    h4 {
      color: ${theme.background.default};
      font-weight: 800;
      font-size: 20px;
      line-height: 1.21;
      letter-spacing: 0.1px;
      padding-top: 8px;
      text-align: left;
      width: 75%;
    }
  }
  &.active {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
`

const HiddenRadio = styled.input.attrs({ type: 'radio' })`
  display: none;
`

interface Props {
  label?: string
  text?: string
  image?: any
  value: any
  option?: any
  onChange: (value: any) => void
}

const Card: React.FC<Props> = props => {
  const { label, text, image, value, option, onChange } = props
  const ref = React.createRef<HTMLInputElement>()

  return (
    <>
      <Link to="/">
        <CardButton
          className={`card-button ${value === option ? 'active' : ''}`}
          style={{ backgroundImage: `url(${image})` }}
          type="button"
          onClick={() => {
            ref.current && ref.current.click()
          }}
        >
          <div className="content-card">
            <span>{label}</span>
            <h4>{text}</h4>
          </div>
        </CardButton>
        <HiddenRadio
          checked={value === option}
          onChange={() => {
            onChange(option)
          }}
          ref={ref}
        />
      </Link>
    </>
  )
}

export default Card
