import React, { useState } from 'react'
import styled from 'styled-components'
import InnovativeSolution from 'models/entities/InnovativeSolution'
import Select from 'components/FormElements/Select'
import InputText from 'components/FormElements/InputText'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .label-container {
    flex-basis: 40%;
    margin-top: 1rem;
    // h4 {
    //   white-space: nowrap;
    // }
    .section-heading {
      font-size: 12px;
      font-weight: 800;
      line-height: 1.75;
      color: #006e31;
      text-transform: uppercase;
    }
  }
  .fields-container {
    flex-basis: 60%;
    min-width: 320px;
    margin: 1rem 0 1.75rem 0;
  }
`

const OtherContainer = styled.div`
  margin-top: 15px;
`

interface Values {
  innovativeSolutionsSelected: InnovativeSolution[]
  otherInnovativeSolutions: string
}

interface Props {
  values: Values
  innovativeSolutions: InnovativeSolution[]
  onChange: (field: string, value: string | InnovativeSolution[]) => void
}

const InnovativeSolutions: React.FC<Props> = ({
  values,
  innovativeSolutions,
  onChange,
}) => {
  const { innovativeSolutionsSelected, otherInnovativeSolutions } = values
  const [showOther, setShowOther] = useState<boolean>(false)

  const onSelectChange = (field: string, value: InnovativeSolution[]) => {
    if (value) {
      setShowOther(!!value.find(v => v.name === 'Other'))
    } else {
      setShowOther(false)
    }
    onChange(field, value)
  }

  return (
    <Container>
      <div className="label-container">
        <span className="section-heading">SECTION 7.</span>
        <h4 className="bold">
          Which Of Our Innovative Solutions Are You Interested In?
        </h4>
      </div>
      <div className="fields-container ">
        <Select
          label="Select The Innovative Solutions You’re Interested In"
          value={innovativeSolutionsSelected}
          name="innovativeSolutions"
          placeholder="You can select multiple"
          multiselect={true}
          options={innovativeSolutions}
          optionsValue="id"
          optionsLabel="name"
          onChange={onSelectChange}
          optionDescription={true}
        />
        {showOther && (
          <OtherContainer>
            <InputText
              value={otherInnovativeSolutions}
              label="Other Innovative Solutions You Are Interested In"
              onChange={e => {
                onChange('otherInnovativeSolutions', e.target.value)
              }}
            />
          </OtherContainer>
        )}
      </div>
    </Container>
  )
}

export default InnovativeSolutions
