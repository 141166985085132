import Axios from '../Client'
import {
  CreateSDEnquiryRequest,
  CreateEnquiryResponse,
} from 'models/api/CreateSDEnquiry'

export default class SDEnquiryService {
  public static async createSDEnquiry(
    payload: CreateSDEnquiryRequest
  ): Promise<CreateEnquiryResponse> {
    const response = await Axios.post<CreateEnquiryResponse>(
      '/smart-dispense/enquiries',
      payload
    )
    return response.data
  }
}
