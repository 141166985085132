import Axios from '../Client'
import {
  CreateEnquiryRequest,
  CreateEnquiryResponse,
} from 'models/api/CreateEnquiry'

export default class EnquiryService {
  public static async createEnquiry(
    payload: CreateEnquiryRequest
  ): Promise<CreateEnquiryResponse> {
    const response = await Axios.post<CreateEnquiryResponse>(
      '/enquiries',
      payload
    )
    return response.data
  }
}
