import React from 'react'
import styled from 'styled-components'
import BusinessType from 'models/entities/BusinessType'
import theme from 'theme'
import Checkbox from 'components/FormElements/Checkbox'
import InputText from 'components/FormElements/InputText'

interface Props {
  businessTypes: BusinessType[]
  value: BusinessType[]
  other: boolean
  otherValue: string
  otherError?: string
  onChange: (value: BusinessType[]) => void
  onSelectOther: (other: boolean) => void
  onOtherEntry: (otherValue: string) => void
}

const Container = styled.div`
  .cards {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    .card-container {
      display: flex;
      flex-basis: calc(25% - 8px);
      margin: 4px;
      min-width: 180px;
      .active {
        background-color: ${theme.background.defaultSelected};
      }
    }
  }

  @media screen and (min-width: 501px) and (max-width: 800px) {
    .cards {
      .card-container {
        flex-basis: calc(50% - 8px);
      }
    }
  }

  @media screen and (max-width: 500px) {
    .cards {
      .card-container {
        flex-basis: calc(100% - 8px);
      }
    }
    .input-container {
      .other-input {
        height: 0 !important;
        padding-bottom: 23%;
      }
    }
  }
`

const BusinessTypeCard = styled.div`
  cursor: pointer;
  display: flex;
  background-color: ${theme.background.defaultAlt};
  width: 100%;
  padding-bottom: 55%;
  border-radius: 0.5rem;
  height: 0px;
  background-position: 50% 60%;
  background-repeat: no-repeat;
  background-size: 150%;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: ${theme.background.default};
    box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.4);
  }
  span {
    position: absolute;
    width: 100%;
    top: 65%;
    left: 0;
    text-align: center;
  }
  .checkbox {
    position: absolute;
    right: 0;
  }
`

const OtherContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .other-container {
    display: flex;
    flex-basis: calc(25% - 8px);
    margin: 4px;
    min-width: 180px;

    .other {
      cursor: pointer;
      display: flex;
      background-color: ${theme.background.defaultAlt};
      width: 100%;
      border-radius: 0.5rem;
      height: 0;
      padding-bottom: 23%
      position: relative;
      .checkbox {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &.active {
        background-color: ${theme.background.defaultSelected};
      }
      span {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        text-align: center;
      }
      &:hover {
        background-color: ${theme.background.default};
        box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.4);
      }
    }
  }

  .input-container {
    flex-grow: 1;
    margin: 4px;
    position: relative;
    .other-input {
      background-color: ${theme.background.default};
      width: 100%;
      border-radius: 0.5rem;
      height: 100%;
      box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.4);
      position: relative;
      

      .input {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 8px;
        box-sizing: border-box;
        .input-wrapper {
          margin: 0;
        }
        span {
          display: none;
        }
      }
    }
    .other-error {
      position: absolute;
      top: calc(100% + 5px);
      margin-left: 0.5rem;
      color:${theme.status.danger}
    }
  }

  @media screen and (min-width: 501px) and (max-width: 800px) {
    .other-container,
    .input-container {
      flex-basis: calc(50% - 8px);
    }
  }

  @media screen and (max-width: 500px) {
    .other-container,
    .input-container {
      flex-basis: calc(100% - 8px);
    }
  }
`

const BusinessTypeSelection: React.FC<Props> = props => {
  const {
    businessTypes,
    value,
    onChange,
    other,
    otherValue,
    otherError,
    onSelectOther,
    onOtherEntry,
  } = props

  const apiOther = businessTypes.find(bt => bt.other === true)

  const setVal = (val: BusinessType) => {
    const index = value.indexOf(val)
    index !== -1
      ? onChange(value.filter((v, i) => i !== index))
      : onChange(value.concat([val]))
  }

  const setOther = () => {
    // Reset the value of other if checked and unchecked
    if (other) {
      onOtherEntry('')
    }

    apiOther && setVal(apiOther)
    onSelectOther(!other)
  }

  return (
    <Container className="business-type-selection">
      <div className="cards">
        {businessTypes
          .filter(bt => !bt.other)
          .map((bt, i) => {
            const { icon, name } = bt

            return (
              <div className="card-container" key={i}>
                <BusinessTypeCard
                  className={`business-type-card ${value.indexOf(bt) !== -1 &&
                    'active'}`}
                  style={{ backgroundImage: `url(${icon})` }}
                  onClick={() => {
                    setVal(bt)
                  }}
                >
                  <Checkbox
                    value={value.indexOf(bt) !== -1}
                    onChange={() => {
                      setVal(bt)
                    }}
                  />
                  <span>{name}</span>
                </BusinessTypeCard>
              </div>
            )
          })}
      </div>
      <OtherContainer>
        <div className="other-container">
          <div
            className={`other ${other && 'active'}`}
            onClick={() => {
              setOther()
            }}
          >
            <Checkbox
              value={other}
              onChange={() => {
                setOther()
              }}
            />
            <span>Other</span>
          </div>
        </div>
        {other && (
          <div className="input-container">
            <div className={`other-input ${other && 'active'}`}>
              <InputText
                value={otherValue}
                onChange={event => {
                  onOtherEntry(event.target.value)
                }}
                validationError={otherError}
                placeholder="Name your business type"
              />
            </div>
            {otherError && <span className="other-error">{otherError}</span>}
          </div>
        )}
      </OtherContainer>
    </Container>
  )
}

export default BusinessTypeSelection
