import React, { useState, createRef } from 'react'
import styled from 'styled-components'
import InputText from 'components/FormElements/InputText'
import Address from 'models/entities/Address'
import PostcodeService from 'services/PostcodeService'
import { ClassicSpinner } from 'react-spinners-kit'
import theme from 'theme'
import Clickaway from 'utils/Clickaway'

export const POSTCODE_REGEX = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/

const Container = styled.div`
  max-width: 220px;
  width: 100%;
  position: relative;
`

const AddressListContainer = styled.div`
  position: absolute;
  top: 100%;
  background-color: white;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.18);
  width: 420px;
  height: 220px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  .text-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  button {
    font-size: 14px;
    font-weight: 500;
    line-height: 2.86;
    color: ${theme.text.body};
    border: 0;
    display: flex;
    width: 100%;
    padding: 0 1rem;
    margin: 0;
    background: transparent;
    cursor: pointer;
    &:focus {
      outline: none;
      background-color: ${theme.background.hr};
    }
  }
  ul {
    overflow-y: auto;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    flex-grow: 1;
    li {
      border-bottom: 1px solid ${theme.background.hr};
      cursor: pointer;
      &:hover {
        background-color: ${theme.background.hr};
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
`

const ManualEntry = styled.button`
  color: ${theme.cta.primary} !important;
  text-decoration: underline;
  border-top: 1px solid ${theme.background.hr} !important;
  min-height: 41px;
  &:hover {
    background-color: ${theme.background.hr};
  }
`

interface Props {
  value: string
  validationError?: string
  onChange: (value: string) => void
  onSelectAddress: (address: Address) => void
}

const PostcodeLookup: React.FC<Props> = props => {
  const { value, validationError, onChange, onSelectAddress } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [showList, setShowList] = useState<boolean>(false)
  const [addressList, setAddressList] = useState<Address[]>([])

  const findAddress = async (postcode: string) => {
    try {
      setLoading(true)
      setAddressList([])
      setShowList(true)
      const addressList = await PostcodeService.searchAddress(postcode)
      setAddressList(addressList)
      setError(false)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const ref = createRef<HTMLDivElement>()
  return (
    <Clickaway
      reference={ref}
      onClickaway={() => {
        setShowList(false)
      }}
    >
      <Container ref={ref}>
        <InputText
          label="Enter Postcode"
          required={true}
          value={value}
          validationError={validationError}
          onChange={event => {
            const val = event.target.value.toUpperCase()
            onChange(val)
            if (val.match(POSTCODE_REGEX)) {
              findAddress(val)
            }
            if (val === '') {
              setShowList(false)
            }
          }}
          onFocus={() => {
            if (addressList.length > 0 && value !== '') {
              setShowList(true)
            }
          }}
          maxLength={8}
          autocomplete={false}
        />
        {showList && (
          <AddressListContainer>
            {loading ? (
              <div className="text-container" data-testid="loading">
                <ClassicSpinner color={theme.cta.primaryPunchy} />
              </div>
            ) : error ? (
              <div className="text-container" data-testid="error">
                Failed to load results
              </div>
            ) : addressList.length === 0 ? (
              <div className="text-container" data-testid="no-results">
                No results
              </div>
            ) : (
              <ul data-testid="results">
                {addressList.map((a, i) => (
                  <li key={i}>
                    <button
                      type="button"
                      onClick={() => {
                        onSelectAddress(a)
                        setShowList(false)
                      }}
                    >
                      {a.formatted}
                    </button>
                  </li>
                ))}
              </ul>
            )}
            <ManualEntry
              type="button"
              onClick={() => {
                setShowList(false)
              }}
            >
              Enter address manually
            </ManualEntry>
          </AddressListContainer>
        )}
      </Container>
    </Clickaway>
  )
}

export default PostcodeLookup
