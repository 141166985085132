import Axios from '../Client'
import Address from 'models/entities/Address'

export default class PostcodeService {
  public static async searchAddress(postcode: string): Promise<Address[]> {
    const response = await Axios.get<Address[]>(
      `/post-code/${postcode.replace(' ', '')}`
    )
    return response.data
  }
}
