import React, { useState } from 'react'
import styled from 'styled-components'
import HeinekenLogo from 'assets/icon/logo-over-dark@2x.png'
import theme from 'theme'

const HeaderContainer = styled.div`
  z-index: 2;
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  padding: 18px;
  width: 100%;
  background-color: ${theme.cta.primaryDark};
  transition: background-color 0.2s ease-in-out;
  .heineken-logo-container {
    img {
      width: 130px;
      height: auto;
    }
  }
  &.top {
    background-color: transparent;
  }
  @media screen and (min-width: 751px) and (max-width: 900px) {
    .heineken-logo-container {
      img {
        width: 120px;
        height: auto;
      }
    }
  }

  @media screen and (min-width: 501px) and (max-width: 750px) {
    padding: 1em;
    .heineken-logo-container {
      img {
        width: 100px;
        height: auto;
      }
    }
  }

  @media screen and (min-width: 460px) and (max-width: 500px) {
    padding: 1em;
    .heineken-logo-container {
      img {
        width: 95px;
        height: auto;
      }
    }
  }
  @media screen and (max-width: 459px) {
    padding: 8px;
    .heineken-logo-container {
      img {
        width: 80px;
        height: auto;
      }
    }
  }
`

const Header: React.FC = props => {
  const [top, setTop] = useState<boolean>(window.pageYOffset === 0)
  window.onscroll = function() {
    setTop(window.pageYOffset === 0)
  }

  return (
    <HeaderContainer className={top ? 'top' : ''}>
      <div className="heineken-logo-container">
        <img src={HeinekenLogo} alt="heineken-logo" />
      </div>
    </HeaderContainer>
  )
}

export default Header
