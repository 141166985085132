import React, { useState } from 'react'
import styled from 'styled-components'
import Select from 'components/FormElements/Select'
import InputText from 'components/FormElements/InputText'
import HeinekenCustomer from 'models/entities/HeinekenCustomer'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .label-container {
    flex-basis: 40%;
    margin-top: 1rem;
    .section-heading {
      font-size: 12px;
      font-weight: 800;
      line-height: 1.75;
      color: #006e31;
      text-transform: uppercase;
    }
  }
  .fields-container {
    flex-basis: 60%;
    min-width: 320px;
    margin: 1rem 0 1.75rem 0;
  }
`

const OtherContainer = styled.div`
  margin-top: 15px;
`

interface Values {
  heinekenCustomer: HeinekenCustomer | null
  heinekenCustomerOther: string
  otherCustomer: boolean
}
interface Errors {
  heinekenCustomer?: string
  heinekenCustomerOther?: string
}

interface Props {
  values: Values
  heinekenSupplier: HeinekenCustomer[]
  onChange: (field: string, value: string | HeinekenCustomer | boolean) => void
  errors: Errors
}

const CurrentHeinekenCustomer: React.FC<Props> = ({
  values,
  heinekenSupplier,
  onChange,
  errors,
}) => {
  const { heinekenCustomer, heinekenCustomerOther } = values
  const [showOther, setShowOther] = useState<boolean>(false)

  const onSelectChange = (field: string, value: HeinekenCustomer) => {
    if (value && value.name === 'No – other') {
      setShowOther(true)
      onChange('otherCustomer', true)
    } else {
      setShowOther(false)
      onChange('otherCustomer', false)
    }
    onChange(field, value)
  }

  return (
    <Container>
      <div className="label-container">
        <span className="section-heading">SECTION 2.</span>
        <h4 className="bold">Do you trade directly with HEINEKEN? *</h4>
      </div>
      <div className="fields-container ">
        <Select
          required={true}
          value={heinekenCustomer}
          onChange={onSelectChange}
          name="heinekenCustomer"
          placeholder="Select"
          options={heinekenSupplier}
          label="Select if you are an existing HEINEKEN customer"
          optionsLabel="name"
          optionsValue="id"
          validationError={errors.heinekenCustomer}
        />
        {showOther && (
          <OtherContainer>
            <InputText
              value={heinekenCustomerOther}
              label="Other kind of supplier *"
              onChange={e => {
                onChange('heinekenCustomerOther', e.target.value)
              }}
              validationError={errors.heinekenCustomerOther}
            />
          </OtherContainer>
        )}
      </div>
    </Container>
  )
}

export default CurrentHeinekenCustomer
