import React from 'react'
import styled from 'styled-components'
import theme from 'theme'

const AnchorComponent = styled.a`
  text-decoration: none;
  padding: 9px 24px;
  border: 0;
  cursor: pointer;
  background-color: ${theme.cta.primary};
  color: white;
  border-radius: 0.5rem;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.13),
    inset 0 4px 6px 0 rgba(0, 0, 0, 0.06),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0) 23%,
    rgba(255, 255, 255, 0.2)
  );
  font-size: 12px;
  font-weight: 500;
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.27),
      inset 0 -1px 0 0 rgba(0, 0, 0, 0.13),
      inset 0 1px 2px 0 rgba(255, 255, 255, 0.1);
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.18),
      rgba(255, 255, 255, 0.25)
    );
  }
  &:focus {
    outline: 0;
    box-shadow: 0px 0px 0.5px 3px rgba(121, 188, 29, 0.7),
      inset 0 -1px 0 0 rgba(0, 0, 0, 0.13),
      inset 0 4px 6px 0 rgba(0, 0, 0, 0.06),
      inset 0 1px 0 0 rgba(255, 255, 255, 0.3);
    &:active {
      box-shadow: 0px 0px 0.5px 3px rgba(121, 188, 29, 0.7),
        inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    }
  }
  &:active {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.12),
      rgba(255, 255, 255, 0.06)
    );
  }
`

interface Props {
  href: string | undefined
  text?: string
  newTab?: boolean
}

const Anchor: React.FC<Props> = props => {
  const { href, text, newTab } = props

  return (
    <AnchorComponent
      className="anchor-component"
      href={href}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noopener noreferrer' : undefined}
    >
      {text}
    </AnchorComponent>
  )
}

export default Anchor
