import React, { useState } from 'react'
import styled from 'styled-components'
import FindOut from 'models/entities/FindOut'
import Select from 'components/FormElements/Select'
import InputText from 'components/FormElements/InputText'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .label-container {
    flex-basis: 40%;
    margin-top: 1rem;
    .section-heading {
      font-size: 12px;
      font-weight: 800;
      line-height: 1.75;
      color: #006e31;
      text-transform: uppercase;
    }
  }
  .fields-container {
    flex-basis: 60%;
    min-width: 320px;
    margin: 1rem 0 1.75rem 0;
  }
`

const OtherContainer = styled.div`
  margin-top: 15px;
`

interface Values {
  findOutSelected: FindOut | null
  otherFindOut: string
  otherFindOutRequired?: boolean
}

interface Error {
  otherFindOut?: string
}

interface Props {
  sectionTitle: string
  selectLabel: string
  sectionNumber: string
  inputLabel: string
  values: Values
  findOuts: FindOut[]
  onChange: (field: string, value: string | FindOut | boolean) => void
  errors?: Error
}

const FindOuts: React.FC<Props> = ({
  values,
  findOuts,
  onChange,
  sectionNumber,
  sectionTitle,
  selectLabel,
  inputLabel,
  errors,
}) => {
  const { findOutSelected, otherFindOut } = values
  const [showOther, setShowOther] = useState<boolean>(false)

  const onSelectChange = (field: string, value: FindOut) => {
    if (value && value.name === 'Other') {
      setShowOther(true)
      onChange('otherFindOutRequired', true)
    } else {
      setShowOther(false)
      onChange('otherFindOutRequired', false)
      onChange('otherFindOut', '')
    }
    onChange(field, value)
  }
  return (
    <Container>
      <div className="label-container">
        <span className="section-heading">{`SECTION ${sectionNumber}.`}</span>
        <h4 className="bold">{sectionTitle}</h4>
      </div>
      <div className="fields-container ">
        <Select
          value={findOutSelected}
          onChange={onSelectChange}
          name="findOut"
          placeholder="Select"
          options={findOuts}
          label={selectLabel}
          optionsLabel="name"
          optionsValue="id"
        />
        {showOther && (
          <OtherContainer>
            <InputText
              value={otherFindOut}
              label={inputLabel}
              onChange={(e) => {
                onChange('otherFindOut', e.target.value)
              }}
              validationError={errors && errors.otherFindOut}
            />
          </OtherContainer>
        )}
      </div>
    </Container>
  )
}

export default FindOuts
