import React, { useState } from 'react'
import styled from 'styled-components'
import Attraction from 'models/entities/Attraction'
import Select from 'components/FormElements/Select'
import InputText from 'components/FormElements/InputText'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .label-container {
    flex-basis: 40%;
    margin-top: 1rem;
    .section-heading {
      font-size: 12px;
      font-weight: 800;
      line-height: 1.75;
      color: #006e31;
      text-transform: uppercase;
    }
  }
  .fields-container {
    flex-basis: 60%;
    min-width: 320px;
    margin: 1rem 0 1.75rem 0;
  }
`

const OtherContainer = styled.div`
  margin-top: 15px;
`

interface Values {
  attractionsSelected: Attraction[]
  otherAttractions: string
}

interface Props {
  values: Values
  attractions: Attraction[]
  onChange: (field: string, value: string | Attraction[]) => void
}

const Attractions: React.FC<Props> = ({ values, attractions, onChange }) => {
  const { attractionsSelected, otherAttractions } = values
  const [showOther, setShowOther] = useState<boolean>(false)

  const onSelectChange = (field: string, value: Attraction[]) => {
    if (value) {
      setShowOther(!!value.find(v => v.name === 'Other'))
    } else {
      setShowOther(false)
    }
    onChange(field, value)
  }

  return (
    <Container>
      <div className="label-container">
        <span className="section-heading">SECTION 6.</span>
        <h4 className="bold">What Factors Attracted You To HEINEKEN?</h4>
      </div>
      <div className="fields-container ">
        <Select
          label="Select The Factors That Attracted You To HEINEKEN"
          value={attractionsSelected}
          name="attractions"
          placeholder="You can select multiple"
          multiselect={true}
          options={attractions}
          optionsValue="id"
          optionsLabel="name"
          onChange={onSelectChange}
        />
        {showOther && (
          <OtherContainer>
            <InputText
              value={otherAttractions}
              label="Other Factors That Attracted You To HEINEKEN"
              onChange={e => {
                onChange('otherAttractions', e.target.value)
              }}
            />
          </OtherContainer>
        )}
      </div>
    </Container>
  )
}

export default Attractions
