import styled from 'styled-components'
import theme from 'theme'

export const EnquiryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  .header {
    min-height: 1px;
    .background-image-container {
      position: relative;
      .heineken-image {
        z-index: -1;
        width: 100%;
        height: auto;
      }
      h1 {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 1em auto;
        width: 50%;
        color: #fff;
        font-size: 45px;
        font-weight: 800;
        letter-spacing: 1px;
        text-align: center;
        text-shadow: 0 1px 6px rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media screen and (min-width: 901px) and (max-width: 1100px) {
    .header {
      .background-image-container {
        h1 {
          width: 50%;
          font-size: 36px;
          margin: 0 auto;
        }
      }
    }
  }

  @media screen and (min-width: 751px) and (max-width: 900px) {
    .header {
      .background-image-container {
        h1 {
          font-size: 30px;
          margin: 0 auto;
        }
      }
    }
  }
  @media screen and (min-width: 501px) and (max-width: 750px) {
    .header {
      .background-image-container {
        h1 {
          width: 55%;
          font-size: 26px;
          margin: 0 auto;
        }
      }
    }
  }

  @media screen and (min-width: 460px) and (max-width: 500px) {
    .header {
      .background-image-container {
        h1 {
          font-size: 20px;
          margin: 0 auto;
        }
      }
    }
  }
  @media screen and (max-width: 459px) {
    .header {
      .background-image-container {
        h1 {
          width: 57%;
          font-size: 16px;
          margin: 0 auto;
        }
      }
    }
  }
`

export const Container = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 1024px;
  margin: 20px auto 100px;
  position: relative;
  h4 {
    color: ${theme.text.splashScreen};
  }
  .submit-container {
    .button {
      margin-left: 0.5rem;
      margin-bottom: 50px;
    }
    .additional-text {
      display: block;
      font-size: 0.75rem;
      margin: 0 0 0.375rem 0.5rem;
    }
    .terms-and-conditions {
      display: flex;
      align-items: center;
      border-top: 1px solid ${theme.background.hr};
      padding: 1.5rem 0;
      p {
        margin: 0;
      }
    }
  }
  .content-container {
    padding: 0.5rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    p {
      text-align: center;
      color: ${theme.text.splashScreen};
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: -1.52px;
    }
    p: last-of-type {
      font-weight: bold;
    }
  }
`

export const Section = styled.section`
  padding: 0.5rem;
  box-sizing: border-box;
  margin: 0.5rem 0;
  border-top: 1px solid ${theme.background.hr};
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  background-color: ${theme.cta.primaryDark};
  .links-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    .heineken-url,
    .cookie-url {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.67;
      color: #fff;
      a {
        color: #fff;
        text-decoration: none;
        letter-spacing: -0.14px;
        text-align: right;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
    .cookie-url a {
      text-align: left;
    }
  }
  .colophon {
    max-width: 1024px;
    margin: 10px auto 10px;
    text-align: center;
    color: #fff;
    font-size: 0.75rem;
  }
`
export const SocialNetworkIcons = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 1rem;
  }
  .facebook-logo {
    width: 8px;
    margin: 0 5px;
  }
`
