import React from 'react'
import styled from 'styled-components'
import Checkbox from 'components/FormElements/Checkbox'
import theme from 'theme'

interface Props {
  value: any[]
  disabled?: boolean
  required?: boolean
  label?: string
  options: any[]
  optionsLabel?: string
  size?: string
  validationError?: string
  onChange: (newVal: any[]) => void
}

const Container = styled.div`
  padding-top: 5px;
  .checkboxes {
    display: flex;
  }
  .checkbox {
    margin-right: 0.5rem;
  }

  &.error {
    button {
      border: 1px solid ${theme.status.dangerLight};
      &:focus {
        box-shadow: 0 0 0.5px 2px ${theme.status.dangerLight},
          0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
      }
    }
  }
`
const ValidationError = styled.span`
  font-size: 0.75rem;
  color: ${theme.status.danger};
  margin-left: 5px;
`

const Label = styled.label`
  font-weight: bold;
  font-size: 0.75rem;
  margin-bottom: 5px;
  color: ${theme.text.body};
  margin-left: 5px;
`

const CheckboxGroup: React.FC<Props> = props => {
  const {
    options,
    value,
    onChange,
    optionsLabel,
    disabled,
    size,
    label,
    required,
    validationError,
  } = props
  return (
    <Container className={`checkbox-group ${validationError && 'error'}`}>
      {label && <Label>{`${label}${required ? ' *' : ''}`}</Label>}

      <div className="checkboxes">
        {options.map((o, i) => (
          <Checkbox
            key={i}
            value={value.indexOf(o) !== -1}
            label={optionsLabel ? o[optionsLabel] : o}
            disabled={disabled}
            size={size}
            onChange={() => {
              const index = value.indexOf(o)
              index !== -1
                ? onChange(value.filter((v, i) => i !== index))
                : onChange(value.concat([o]))
            }}
          />
        ))}
      </div>
      {validationError && (
        <ValidationError className="validation-error">
          {validationError}
        </ValidationError>
      )}
    </Container>
  )
}

export default CheckboxGroup
