import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Formik, FormikProps, FormikErrors } from 'formik'
import { Redirect, Link } from 'react-router-dom'
import { showToast } from 'components/Toast'
import * as Yup from 'yup'
import theme from 'theme'
import OptionsDetails from 'components/NewEnquiry/OptionsDetails'
import OutletDetails from 'components/NewEnquiry/OutletDetails'
import CurrentSupplier from 'components/NewEnquiry/CurrentSupplier'
import SeasonalTrader from 'components/NewEnquiry/SeasonalTrader'
import BusinessTypes from 'components/NewEnquiry/BusinessTypes'
import Attractions from 'components/NewEnquiry/Attractions'
import InnovativeSolutions from 'components/NewEnquiry/InnovativeSolutions'
import FindOuts from 'components/NewEnquiry/FindOuts'
import ContactDetails from 'components/NewEnquiry/ContactDetails'
import Brands from 'components/NewEnquiry/Brands'
import GlobalContext from 'contexts/GlobalContext'
import Button from 'components/Button'
import Header from 'components/Header'
import NewSDEnquiryForm from 'components/NewSDEnquiryForm'
import { POSTCODE_REGEX } from 'components/PostcodeLookup/PostcodeLookup'
import Lang from 'lang'
import EnquiryService from 'services/EnquiryService'
import Region from 'models/entities/Region'
import Supplier from 'models/entities/Supplier'
import BusinessType from 'models/entities/BusinessType'
import Brand from 'models/entities/Brand'
import Attraction from 'models/entities/Attraction'
import InnovativeSolution from 'models/entities/InnovativeSolution'
import FindOut from 'models/entities/FindOut'
import ContactMethod from 'models/entities/ContactMethod'
import GroupImg from 'assets/icon/group.png'
import { UK_PHONE_REGEX } from 'utils/ValidationHelper'
import { EnquiryPageContainer, Container, Section, Footer } from './style'

// FIXME: don't call JS from inside href
/* eslint jsx-a11y/anchor-is-valid: 0 */
/* eslint no-script-url: 0 */

interface FormValues {
  newCustomer: boolean | null
  soldTo: string
  moreOutlets: boolean
  outletName: string
  outletPhone: string
  postcode: string
  addressLine1: string
  addressLine2: string
  addressLine3: string
  town: string
  region: Region | null
  currentSupplier: Supplier[]
  tradeAllYear: boolean | null
  tradeMonths: number[]
  businessTypes: BusinessType[]
  other: boolean
  otherValue: string
  brands: Brand[]
  attractions: Attraction[]
  otherAttractions: string
  innovativeSolutions: InnovativeSolution[]
  otherInnovativeSolutions: string
  findOut: FindOut | null
  otherFindOut: string
  otherFindOutRequired: boolean
  swifty: boolean | null
  firstName: string
  lastName: string
  email: string
  mobile: string
  businessPhone: string
  contactMethod: ContactMethod[]
  terms: boolean
}

// TODO: Refactor this into its own re-usable file
const FormEffect = (props: {
  formik: FormikProps<FormValues>
  onValidationError: (formik: FormikProps<FormValues>) => void
}) => {
  const { onValidationError, formik } = props

  const effect = () => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      onValidationError(formik)
    }
  }
  React.useEffect(effect, [formik.submitCount, formik.isSubmitting])

  return null
}

const formMap = {
  newCustomer: 'newCustomer',
  soldTo: 'soldTo',
  moreOutlets: 'moreOutlets',
  outletName: 'outlet.name',
  outletPhone: 'outlet.phone',
  postcode: 'outlet.postCode',
  addressLine1: 'outlet.line1',
  addressLine2: 'outlet.line2',
  addressLine3: 'outlet.line3',
  town: 'outlet.town',
  region: 'outlet.regionId',
  tradeAllYear: 'outlet.seasonalTrader',
  tradeMonths: 'outlet.seasonMonths',
  otherValue: 'outlet.otherBusinessType',
  firstName: 'primaryContact.name',
  lastName: 'primaryContact.lastName',
  email: 'primaryContact.email',
  mobile: 'primaryContact.mobile',
  businessPhone: 'primaryContact.landline',
  contactMethod: 'primaryContact.contactMethod',
  swifty: 'offers.swifty',
}

const formatValidationErrors = (
  validationErrors: { field: string; message: string }[]
): FormikErrors<FormValues> => {
  return validationErrors.reduce((acc, error) => {
    const field = Object.keys(formMap).find(
      (k) => (formMap as any)[k] === error.field
    )
    field && ((acc as any)[field] = error.message)
    return acc
  }, {})
}

const NewEnquiry: React.FC = () => {
  const {
    suppliers,
    regions,
    businessTypes,
    brands,
    attractions,
    innovativeSolutions,
    findOuts,
    currentApplicationState,
  } = GlobalContext.Consumer()
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)

  return (
    <ThemeProvider theme={theme}>
      <EnquiryPageContainer className="enquiry-page-container">
        <div className="header">
          <div className="background-image-container">
            <img
              src={GroupImg}
              alt="heineken-group"
              className="heineken-image"
            />
            <h1>Welcome to HEINEKEN</h1>
          </div>
          <Header />
        </div>

        <Container>
          <div className="content-container">
            <p>
              We believe pubs and bars like yours are a vital part of our
              communities, that’s why we want to partner with you and help your
              business grow.
            </p>
            <p>
              Whether it’s our iconic beer and cider brands, dedicated customer
              support teams or the latest trends and insights we share with you,
              we’re committed to helping you attract more customers through your
              door.
            </p>
            <p>Make the first step to opening a HEINEKEN account.</p>
          </div>
          {success && <Redirect to="/success" />}

          <Formik
            initialValues={{
              newCustomer: null,
              soldTo: '',
              moreOutlets: false,
              outletName: '',
              outletPhone: '',
              postcode: '',
              addressLine1: '',
              addressLine2: '',
              addressLine3: '',
              town: '',
              region: null,
              currentSupplier: [],
              tradeAllYear: null,
              tradeMonths: [],
              businessTypes: [],
              other: false,
              otherValue: '',
              brands: [],
              attractions: [],
              otherAttractions: '',
              innovativeSolutions: [],
              otherInnovativeSolutions: '',
              findOut: null,
              otherFindOut: '',
              otherFindOutRequired: false,
              swifty: false,
              firstName: '',
              lastName: '',
              email: '',
              mobile: '',
              businessPhone: '',
              contactMethod: [],
              terms: false,
            }}
            validateOnChange={hasSubmitted}
            onSubmit={async (values: FormValues, actions) => {
              try {
                setLoading(true)
                await EnquiryService.createEnquiry({
                  newCustomer:
                    values.newCustomer !== null ? values.newCustomer : true,
                  soldTo: values.soldTo,
                  moreOutlets: values.moreOutlets,
                  privacyPolicy: true,
                  outlet: {
                    // TODO: This needs to be updated to something not static once the api is ready
                    typeId: 1,
                    name: values.outletName,
                    phone: values.outletPhone,
                    currentSupplierIds: values.currentSupplier.map((s) => s.id),
                    brandInterestIds: values.brands.map((b) => b.id),
                    businessTypeIds: values.businessTypes.map((b) => b.id),
                    otherBusinessType: values.otherValue,
                    seasonalTrader:
                      values.tradeAllYear !== null
                        ? !values.tradeAllYear
                        : null,
                    seasonMonths: values.tradeMonths,
                    line1: values.addressLine1,
                    line2: values.addressLine2,
                    line3: values.addressLine3,
                    town: values.town,
                    regionId: values.region ? values.region.id : null,
                    postCode: values.postcode,
                    switchReasons: values.attractions.map((a) => a.id),
                    otherSwitchReasons: values.attractions.find(
                      (v) => v.name === 'Other'
                    )
                      ? values.otherAttractions
                      : '',

                    interestedSolutions: values.innovativeSolutions.map(
                      (i) => i.id
                    ),
                    otherInterestedSolution: values.innovativeSolutions.find(
                      (v) => v.name === 'Other'
                    )
                      ? values.otherInnovativeSolutions
                      : '',

                    howDidFindOut: values.findOut ? values.findOut.id : null,
                    otherFindOut:
                      values.findOut && values.findOut.name === 'Other'
                        ? values.otherFindOut
                        : '',
                  },
                  primaryContact: {
                    name: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    mobile: values.mobile,
                    landline: values.businessPhone,
                    contactMethod: values.contactMethod.map(
                      (cm) => ContactMethod[cm]
                    ),
                    marketingOptIn: false,
                  },
                  offers: {
                    swifty: !!values.swifty,
                  },
                })
                setSuccess(true)
              } catch (error) {
                const { response } = error
                if (response && response.status === 400) {
                  const validationErrors = formatValidationErrors(
                    response.data.validationErrors
                  )
                  actions.setErrors(validationErrors)
                  showToast.error('You have validation errors!')
                } else {
                  showToast.error('An error has occurred, please try again.')
                }
              } finally {
                setLoading(false)
              }
            }}
            validationSchema={Yup.object().shape({
              newCustomer: Yup.boolean()
                .nullable()
                .required(Lang.validation.required),
              soldTo: Yup.string().when('newCustomer', {
                is: false,
                then: Yup.string().required(Lang.validation.required),
              }),
              moreOutlets: Yup.boolean().nullable(),
              outletName: Yup.string().required(Lang.validation.required),
              outletPhone: Yup.string()
                .required(Lang.validation.required)
                .matches(UK_PHONE_REGEX, Lang.validation.phone),
              postcode: Yup.string()
                .required(Lang.validation.required)
                .matches(POSTCODE_REGEX, Lang.validation.postcode),
              addressLine1: Yup.string().required(Lang.validation.required),
              town: Yup.string().required(Lang.validation.required),
              otherValue: Yup.string().when('other', {
                is: true,
                then: Yup.string().required(Lang.validation.required),
              }),
              tradeAllYear: Yup.boolean()
                .nullable()
                .required(Lang.validation.required),
              tradeMonths: Yup.array().when('tradeAllYear', {
                is: false,
                then: Yup.array().required(Lang.validation.multiselect),
              }),
              otherFindOut: Yup.string().when('otherFindOutRequired', {
                is: true,
                then: Yup.string().required(Lang.validation.required),
              }),
              swifty: Yup.boolean()
                .nullable()
                .required(Lang.validation.required),
              firstName: Yup.string().required(Lang.validation.required),
              lastName: Yup.string().required(Lang.validation.required),
              email: Yup.string()
                .required(Lang.validation.required)
                .email(Lang.validation.email),
              mobile: Yup.string()
                .required(Lang.validation.required)
                .matches(UK_PHONE_REGEX, Lang.validation.phone),
              businessPhone: Yup.string()
                .required(Lang.validation.required)
                .matches(UK_PHONE_REGEX, Lang.validation.phone),
              contactMethod: Yup.array().required(Lang.validation.multiselect),
            })}
          >
            {(props: FormikProps<FormValues>) => {
              const { handleSubmit, setFieldValue, values, errors } = props
              return (
                <form
                  onSubmit={(event) => {
                    event.preventDefault()
                    setHasSubmitted(true)
                    handleSubmit()
                  }}
                >
                  <FormEffect
                    formik={props}
                    onValidationError={() => {
                      showToast.error('You have validation errors!')
                    }}
                  />
                  <OptionsDetails
                    onChange={setFieldValue}
                    values={{
                      newCustomer: values.newCustomer,
                      moreOutlets: values.moreOutlets,
                      soldTo: values.soldTo,
                    }}
                    errors={{
                      soldTo: errors.soldTo,
                    }}
                  />

                  {currentApplicationState === 'DIRECT' &&
                    values.newCustomer !== null && (
                      <>
                        <Section>
                          <OutletDetails
                            onChange={setFieldValue}
                            regions={regions}
                            values={{
                              outletName: values.outletName,
                              outletPhone: values.outletPhone,
                              postcode: values.postcode,
                              addressLine1: values.addressLine1,
                              addressLine2: values.addressLine2,
                              addressLine3: values.addressLine3,
                              town: values.town,
                              region: values.region,
                            }}
                            errors={{
                              outletName: errors.outletName,
                              outletPhone: errors.outletPhone,
                              postcode: errors.postcode,
                              addressLine1: errors.addressLine1,
                              town: errors.town,
                            }}
                          />
                        </Section>
                        <Section>
                          <CurrentSupplier
                            value={values.currentSupplier}
                            onChange={setFieldValue}
                            suppliers={suppliers}
                          />
                        </Section>
                        <Section>
                          <SeasonalTrader
                            values={{
                              tradeAllYear: values.tradeAllYear,
                              tradeMonths: values.tradeMonths,
                            }}
                            errors={{
                              tradeAllYear: errors.tradeAllYear,
                              tradeMonths: errors.tradeMonths as
                                | string
                                | undefined,
                            }}
                            required={true}
                            onChange={setFieldValue}
                          />
                        </Section>
                        <Section>
                          <BusinessTypes
                            values={{
                              selectedBusinessTypes: values.businessTypes,
                              other: values.other,
                              otherValue: values.otherValue,
                            }}
                            error={errors.otherValue}
                            onChange={setFieldValue}
                            businessTypes={businessTypes}
                            sectionNumber="4"
                          />
                        </Section>
                        <Section>
                          <Brands
                            value={values.brands}
                            brands={brands}
                            onChange={setFieldValue}
                          />
                        </Section>
                        <Section>
                          <Attractions
                            values={{
                              attractionsSelected: values.attractions,
                              otherAttractions: values.otherAttractions,
                            }}
                            onChange={setFieldValue}
                            attractions={attractions}
                          />
                        </Section>
                        <Section>
                          <InnovativeSolutions
                            values={{
                              innovativeSolutionsSelected:
                                values.innovativeSolutions,
                              otherInnovativeSolutions:
                                values.otherInnovativeSolutions,
                            }}
                            onChange={setFieldValue}
                            innovativeSolutions={innovativeSolutions}
                          />
                        </Section>
                        <Section>
                          <FindOuts
                            values={{
                              findOutSelected: values.findOut,
                              otherFindOut: values.otherFindOut,
                              otherFindOutRequired: values.otherFindOutRequired,
                            }}
                            onChange={setFieldValue}
                            findOuts={findOuts}
                            sectionNumber="8"
                            sectionTitle="How Did You Find Out About Us?"
                            selectLabel="Select How You Found Out About Us"
                            inputLabel="Other Way You Found Out About Us *"
                            errors={{
                              otherFindOut: errors.otherFindOut,
                            }}
                          />
                        </Section>
                        <Section>
                          <ContactDetails
                            values={{
                              firstName: values.firstName,
                              lastName: values.lastName,
                              email: values.email,
                              mobile: values.mobile,
                              businessPhone: values.businessPhone,
                              contactMethod: values.contactMethod,
                            }}
                            errors={{
                              firstName: errors.firstName,
                              lastName: errors.lastName,
                              email: errors.email,
                              mobile: errors.mobile,
                              businessPhone: errors.businessPhone,
                              // Have to manually type this as theres an issue with Formik typing
                              contactMethod: errors.contactMethod as
                                | string
                                | undefined,
                            }}
                            onChange={setFieldValue}
                            sectionNumber="9"
                          />
                        </Section>
                        <div className="submit-container">
                          <span className="additional-text">
                            For more information on how Heineken UK Limited
                            handle your personal data please see our{' '}
                            <a
                              href="/privacypolicy"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Privacy Policy
                            </a>
                          </span>
                          <Button
                            text="Submit"
                            type="submit"
                            size="large"
                            loading={loading}
                          />
                        </div>
                      </>
                    )}
                </form>
              )
            }}
          </Formik>
          {currentApplicationState === 'SMART-DISPENSE' && <NewSDEnquiryForm />}
        </Container>
        <Footer className="footer">
          <div className="links-container">
            {/* Commenting out social media links for intitial deployment */}
            {/* <SocialNetworkIcons className="social-network-wrapper">
              <a
                href={process.env.REACT_APP_HEINEKEN_TWITTER_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="twitter-logo" src={TwitterLogo} />
              </a>
              <a
                href={process.env.REACT_APP_HEINEKEN_FACEBOOK_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="facebook-logo"
                  src={FacebookLogo}
                  className="facebook-logo"
                />
              </a>
              <a
                href={process.env.REACT_APP_HEINEKEN_INSTAGRAM_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="instagram-logo" src={InstagramLogo} />
              </a> </SocialNetworkIcons>*/}

            <div className="cookie-url">
              {/* <a
                href={process.env.REACT_APP_TERMS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>  */}
              <Link target="_blank" to="/privacypolicy">
                Privacy Policy
              </Link>{' '}
              &middot;
              {/* <a
                href={process.env.REACT_APP_HEINEKEN_COOKIE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </a>  */}
              <Link target="_blank" to="/cookiepolicy">
                Cookie Policy
              </Link>{' '}
              &middot;
              {/* <a
                href={process.env.REACT_APP_HEINEKEN_TERMS_OF_USE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use
              </a>  */}
              <Link target="_blank" to="/termsofuse">
                Terms of Use
              </Link>{' '}
              &middot;
              <a href="javascript:Bootstrapper.gateway.openModal();">
                Cookie Settings
              </a>{' '}
              &middot;{' '}
              <a
                href={process.env.REACT_APP_HEINEKEN_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                www.heineken.co.uk
              </a>
            </div>
            {/* <div className="heineken-url">
              <a
                href={process.env.REACT_APP_HEINEKEN_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                www.heineken.co.uk
              </a>
            </div> */}
          </div>
          <p className="colophon">
            &copy; Heineken UK Limited, 2020. All rights reserved. Heineken UK
            Limited. Registered in Scotland. Registered company number:
            SC065527. <br />
            Registered VAT number: 268644912. Registered office: 3-4 Broadway
            Park, South Gyle Broadway, Edinburgh EH12 9JZ
          </p>
        </Footer>
      </EnquiryPageContainer>
    </ThemeProvider>
  )
}

export default NewEnquiry
