import React, { useState, createContext, useEffect } from 'react'
import Supplier from 'models/entities/Supplier'
import SupplierService from 'services/SupplierService'
import RegionService from 'services/RegionService'
import BusinessTypeService from 'services/BusinessTypeService'
import BrandService from 'services/BrandService'
import Region from 'models/entities/Region'
import axios from 'axios'
import BusinessType from 'models/entities/BusinessType'
import Brand from 'models/entities/Brand'
import Attraction from 'models/entities/Attraction'
import AttractionService from 'services/AttractionService'
import InnovativeSolution from 'models/entities/InnovativeSolution'
import InnovativeSolutionService from 'services/InnovativeSolutionService'
import FindOut from 'models/entities/FindOut'
import HeinekenCustomer from 'models/entities/HeinekenCustomer'
import FindOutService from 'services/FindOutService'
import HeinekenService from 'services/HeinekenCustomerService'

interface GlobalContextInterface {
  initialised: boolean
  error: boolean
  loading: boolean
  suppliers: Supplier[]
  regions: Region[]
  businessTypes: BusinessType[]
  brands: Brand[]
  attractions: Attraction[]
  innovativeSolutions: InnovativeSolution[]
  findOuts: FindOut[]
  heinekenCustomers: HeinekenCustomer[]
  loadGlobalContextData: () => void
  currentApplicationState: string
  setApplicationState: () => void
}

const GlobalContext = createContext<GlobalContextInterface>({
  initialised: false,
  error: false,
  loading: false,
  suppliers: [],
  regions: [],
  businessTypes: [],
  brands: [],
  attractions: [],
  innovativeSolutions: [],
  findOuts: [],
  heinekenCustomers: [],
  currentApplicationState: '',
  loadGlobalContextData: () => null,
  setApplicationState: () => '',
})

export const Consumer: () => GlobalContextInterface = () => {
  const consumer = React.useContext(GlobalContext)

  // Condition used to determine if Context Provider has been declared
  if (consumer.initialised === false) {
    throw new Error('GlobalContextProvider not initialised')
  }
  return consumer
}

interface Props {
  loadTimeout?: number
}

export const Provider: React.FC<Props> = ({ loadTimeout, children }) => {
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [suppliers, setSuppliers] = useState<Supplier[]>([])
  const [regions, setRegions] = useState<Region[]>([])
  const [businessTypes, setBusinessTypes] = useState<BusinessType[]>([])
  const [brands, setBrands] = useState<Brand[]>([])
  const [attractions, setAttractions] = useState<Attraction[]>([])
  const [innovativeSolutions, setInnovativeSolutions] = useState<
    InnovativeSolution[]
  >([])
  const [findOuts, setFndOuts] = useState<FindOut[]>([])
  const [heinekenCustomers, setHeinekenCustomers] = useState<
    HeinekenCustomer[]
  >([])
  const [currentApplicationState, setCurrentApplicationState] = useState('')

  const loadGlobalContextData = async () => {
    try {
      setLoading(true)
      setError(false)
      const [
        suppliers,
        regions,
        businessTypes,
        brands,
        attractions,
        innovativeSolutions,
        findOuts,
        heinekenCustomers,
      ] = (await axios.all([
        SupplierService.listSuppliers(),
        RegionService.listRegions(),
        BusinessTypeService.listBusinessTypes(),
        BrandService.listBrands(),
        AttractionService.listAttractions(),
        InnovativeSolutionService.listInnovativeSolutions(),
        FindOutService.listFindOuts(),
        HeinekenService.listHeinekenCustomers(),
      ])) as [
        Supplier[],
        Region[],
        BusinessType[],
        Brand[],
        Attraction[],
        InnovativeSolution[],
        FindOut[],
        HeinekenCustomer[]
      ]
      setSuppliers(suppliers)
      setRegions(regions)
      setBusinessTypes(businessTypes)
      setBrands(brands)
      setAttractions(attractions)
      setInnovativeSolutions(innovativeSolutions)
      setFndOuts(findOuts)
      setHeinekenCustomers(heinekenCustomers)
    } catch {
      setError(true)
    } finally {
      // Intentionally slowing down the time it takes for this function to resolve,
      // to simulate a longer loading state
      setTimeout(() => {
        setLoading(false)
      }, loadTimeout || 1500)
    }
  }

  // on mount of context
  useEffect(() => {
    loadGlobalContextData()
    setApplicationState()
    // eslint-disable-next-line
  }, [])

  //TO DO: This would be better retrieved from an API
  const setApplicationState = () => {
    setCurrentApplicationState(
      window.location.pathname === '/smartdispense'
        ? 'SMART-DISPENSE'
        : 'DIRECT'
    )
  }
  return (
    <GlobalContext.Provider
      value={{
        initialised: true,
        error,
        loading,
        suppliers,
        regions,
        businessTypes,
        brands,
        attractions,
        innovativeSolutions,
        loadGlobalContextData,
        findOuts,
        heinekenCustomers,
        currentApplicationState,
        setApplicationState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
