import React from 'react'
import styled from 'styled-components'
import PintGlass from 'assets/icon/bitmap@2x.png'
import Button from 'components/Button'
import theme from 'theme'

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      box-sizing: border-box;
      padding-bottom: 30px;
      font-size: 35px;
      font-weight: 800;
      color: ${theme.text.splashScreen};
    }
    p {
      box-sizing: border-box;
      padding: 0 8px 18px 8px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: ${theme.text.splashScreen};
    }
    button {
      font-size: 14px;  
      margin-bottom: 40px;
    }
  }
  img {
    box-sizing: border-box;
    margin-top: 40px;
    width: 200px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
  }
`
interface Props {
  onRetry: () => void
}
const ErrorScreen: React.FC<Props> = props => {
  const { onRetry } = props

  return (
    <ErrorContainer>
      <div className="content-container">
        <h1>Oops!</h1>
        <p>Something went wrong. Try loading the page again.</p>
        <Button
          text="Retry"
          type="button"
          onClick={() => {
            onRetry()
          }}
        />
      </div>
      <img src={PintGlass} alt="pint-glass" className="pint-glass" />
    </ErrorContainer>
  )
}

export default ErrorScreen
