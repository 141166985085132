import React, { useEffect } from 'react'
interface Props {
  reference: React.RefObject<HTMLElement>
  onClickaway: () => void
}

const Clickaway: React.FC<Props> = props => {
  const { reference, onClickaway, children } = props

  const handleClick = (event: MouseEvent) => {
    if (
      reference &&
      reference.current &&
      !reference.current.contains(event.target as HTMLElement)
    ) {
      onClickaway()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  })

  return <>{children}</>
}
export default Clickaway
