import 'core-js'
import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import 'stylesheets/app.scss'
import App from 'components/App'
import * as serviceWorker from './serviceWorker'
import GlobalContext from 'contexts/GlobalContext'
import Toast from 'components/Toast'

ReactDOM.render(
  <GlobalContext.Provider>
    <App />
    <Toast />
  </GlobalContext.Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
