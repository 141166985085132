import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import BusinessTypeSelection from 'components/NewEnquiry/BusinessTypes/BusinessTypeSelection'
import BusinessType from 'models/entities/BusinessType'

interface Values {
  selectedBusinessTypes: BusinessType[]
  other: boolean
  otherValue: string
}

interface Props {
  businessTypes: BusinessType[]
  values: Values
  error?: string
  sectionNumber: string
  onChange: (field: string, value: BusinessType[] | boolean | string) => void
}

const Container = styled.div`
  .section-heading {
    font-size: 12px;
    font-weight: 800;
    line-height: 1.75;
    color: #006e31;
    text-transform: uppercase;
  }
  .additional-label {
    display: flex;
    color: ${theme.text.secondary};
    margin-top: 0.375rem;
  }
  .business-type-selection {
    margin: 1.5rem 0;
  }
`

const BusinessTypes: React.FC<Props> = ({
  businessTypes,
  values,
  error,
  sectionNumber,
  onChange,
}) => {
  const { selectedBusinessTypes, other, otherValue } = values
  return (
    <Container>
      <span className="section-heading">{`SECTION ${sectionNumber}.`}</span>
      <h4 className="bold">Your Outlet Type</h4>
      <span className="additional-label">
        Which of the following best describes your business?
      </span>
      <BusinessTypeSelection
        businessTypes={businessTypes}
        value={selectedBusinessTypes}
        other={other}
        otherValue={otherValue}
        otherError={error}
        onChange={val => {
          onChange('businessTypes', val)
        }}
        onSelectOther={val => {
          onChange('other', val)
        }}
        onOtherEntry={val => {
          onChange('otherValue', val)
        }}
      />
    </Container>
  )
}

export default BusinessTypes
