import React from 'react'
import styled from 'styled-components'
import InputText from 'components/FormElements/InputText'
import Select from 'components/FormElements/Select'
import PostcodeLookup from 'components/PostcodeLookup'
import Region from 'models/entities/Region'
import theme from 'theme'

interface Values {
  outletName: string
  outletPhone: string
  postcode: string
  addressLine1: string
  addressLine2: string
  addressLine3: string
  town: string
  region: Region | null
}

interface Errors {
  outletName?: string
  outletPhone?: string
  postcode?: string
  addressLine1?: string
  town?: string
  region?: string
}

interface Props {
  regions: Region[]
  values: Values
  errors: Errors
  onChange: (field: string, value: string | Region) => void
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  .label-container {
    flex-basis: 40%;
    margin-top: 1rem;
    h4 {
      white-space: nowrap;
    }
    span {
      display: flex;
      max-width: 300px;
      color: ${theme.text.secondary};
      margin-top: 0.375rem;
    }
    .section-heading {
      font-size: 12px;
      font-weight: 800;
      line-height: 1.75;
      color: #006e31;
      text-transform: uppercase;
      margin-top: 0;
    }
  }
  .fields-container {
    flex-basis: 60%;
    min-width: 320px;
    margin: 1rem 0 1.75rem 0;
  }
`

const OutletDetails: React.FC<Props> = ({
  values,
  errors,
  onChange,
  regions,
}) => {
  const {
    outletName,
    outletPhone,
    postcode,
    addressLine1,
    addressLine2,
    addressLine3,
    town,
    region,
  } = values
  return (
    <Container>
      <div className="label-container">
        <span className="section-heading">SECTION 1.</span>
        <h4 className="bold">Outlet Details</h4>
        <span>
          Adding more than one outlet? Please supply details for your main
          outlet.
        </span>
      </div>
      <div className="fields-container">
        <InputText
          value={outletName}
          label="Outlet Name"
          validationError={errors.outletName}
          onChange={e => {
            onChange('outletName', e.target.value)
          }}
          required={true}
        />
        <InputText
          value={outletPhone}
          label="Outlet Phone"
          maxLength={15}
          validationError={errors.outletPhone}
          onChange={e => {
            onChange('outletPhone', e.target.value)
          }}
          required={true}
          type="tel"
        />
        <PostcodeLookup
          value={postcode}
          onChange={value => {
            onChange('postcode', value)
          }}
          validationError={errors.postcode}
          onSelectAddress={address => {
            onChange('addressLine1', address.line1)
            onChange('addressLine2', address.line2 || '')
            onChange('addressLine3', address.line3 || '')
            onChange('town', address.town)
            onChange('region', address.region)
          }}
        />
        <InputText
          value={addressLine1}
          label="Address Line 1"
          validationError={errors.addressLine1}
          onChange={e => {
            onChange('addressLine1', e.target.value)
          }}
          required={true}
        />
        <InputText
          value={addressLine2}
          label="Address Line 2"
          onChange={e => {
            onChange('addressLine2', e.target.value)
          }}
        />
        <InputText
          value={addressLine3}
          label="Address Line 3"
          onChange={e => {
            onChange('addressLine3', e.target.value)
          }}
        />
        <InputText
          value={town}
          label="Town"
          validationError={errors.town}
          onChange={e => {
            onChange('town', e.target.value)
          }}
          required={true}
        />
        <Select
          value={region}
          onChange={onChange}
          name="region"
          placeholder="Select"
          options={regions}
          label="Region"
          optionsLabel="name"
          optionsValue="id"
        />
      </div>
    </Container>
  )
}

export default OutletDetails
