import React from 'react'
import ReactSelect, { components } from 'react-select'
import styled from 'styled-components'
import theme from 'theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
`
const Label = styled.label`
  font-weight: bold;
  font-size: 0.75rem;
  margin-bottom: 5px;
`

const ValidationError = styled.span`
  font-size: 0.75rem;
  color: ${theme.status.danger};
`

interface Props {
  value: any | null
  options: any[]
  optionsLabel?: string
  optionsValue?: string
  label?: string
  placeholder?: string
  name?: string
  validationError?: string
  multiselect?: boolean
  required?: boolean
  disabled?: boolean
  onChange: (field: string, value: any) => void
  optionDescription?: boolean
  optionDescriptionLabel?: string
}

const selectTheme = {
  primary: theme.cta.primary,
  primary75: theme.cta.primaryPunchy,
  primary50: theme.cta.primaryPunchy,
  primary25: theme.cta.primaryLight,
  danger: theme.status.danger,
  dangerLight: theme.status.dangerLight,
  neutral0: theme.background.default,
  neutral5: theme.background.default,
  neutral10: theme.text.disabled,
  neutral20: theme.text.disabled,
  neutral30: theme.text.disabled,
  neutral40: theme.text.disabled,
  neutral50: theme.text.disabled,
  neutral60: theme.text.secondary,
  neutral70: theme.text.secondary,
  neutral80: theme.text.body,
  neutral90: theme.text.heading,
}

const Select: React.FC<Props> = props => {
  const {
    value,
    options,
    label,
    disabled,
    multiselect,
    name,
    placeholder,
    required,
    validationError,
    optionsLabel,
    optionsValue,
    onChange,
    optionDescription,
    optionDescriptionLabel,
  } = props

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,

      boxShadow:
        state.isFocused && validationError
          ? `0px 0px 0.5px 2px ${theme.status.dangerLight}, 0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05)`
          : state.isFocused && !disabled
          ? '0px 0px 0.5px 3px rgba(121,188,29,0.7), 0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05)'
          : 'inset 0 0 0 0 rgba(0, 0, 0, 0.06), inset 0 1px 3px 0 rgba(0, 0, 0, 0.06)',
      margin: '0px',
      borderRadius: '0.75rem',
      borderWidth: state.isFocused && !disabled ? '1px' : '1px',
      borderColor: validationError
        ? theme.status.dangerLight
        : !state.isFocused || disabled
        ? theme.background.hr
        : theme.cta.primaryLight,
      fontSize: '13px',
      flexWrap: 'nowrap',
      cursor: disabled ? 'not-allowed' : 'pointer',
      backgroundColor: '#f4f5f6',
      '&:hover': {
        boxShadow:
          'box-shadow: 0 1px 2px 0 rgba(0,0,0,0.16), 0 1px 0 0 rgba(0,0,0,0.05);',
        borderColor: validationError
          ? theme.status.dangerLight
          : !state.isFocused || disabled
          ? theme.background.hr
          : 'rgba(121,188,29,0.7)',
      },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      width: '400px',
      display: disabled ? 'none' : 'block',
      fontSize: '13px',
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      overflow: 'hidden',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
    }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      color: theme.background.default,
      borderRadius: '3px',
      boxShadow: '0 2px 4px 0 #50afa8',
      backgroundColor: theme.cta.primary,
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '3px',
      color: theme.background.default,
    }),
    multiValueRemove: (provided: any, state: any) => ({
      ...provided,
      color: theme.background.default,
      borderRadius: '3px',
      backgroundColor: theme.cta.primary,
      '&:hover': {
        color: theme.background.default,
        backgroundColor: theme.cta.primary,
        cursor: 'pointer',
      },
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      display: 'flex',
    }),
    indicatorContainer: (provided: any, state: any) => ({
      padding: '3px',
      color: theme.text.body,
    }),
  }

  const OptionWrapper = styled.div`
    .option-wrapper-label {
      font-weight: 500;
    }
    .option-wrapper-description {
      padding: 4px 12px 8px 12px;
      color: ${theme.text.secondary};
      border-bottom: 0.1px solid ${theme.text.disabled};
    }
    :last-of-type {
      .option-wrapper-description {
        border-bottom: none;
        padding-bottom: 4px;
      }
    }
  `

  const Option = (props: any) => {
    if (optionDescription) {
      //options have a description so update display
      const optionDescriptionProperty = optionDescriptionLabel
        ? optionDescriptionLabel
        : 'description'
      return (
        <OptionWrapper>
          <div className="option-wrapper-label">
            <components.Option {...props} />
          </div>
          <div className="option-wrapper-description">
            {props.data[optionDescriptionProperty]}
          </div>
        </OptionWrapper>
      )
    }
    //options dont have a description so return with no change to display
    return <components.Option {...props} />
  }

  return (
    <Container className="select">
      {label !== undefined && (
        <Label>{`${label}${required ? ' *' : ''}`}</Label>
      )}
      <ReactSelect
        components={{ Option }}
        value={value}
        options={options}
        isMulti={multiselect}
        isSearchable={true}
        isClearable={true}
        placeholder={placeholder}
        theme={t => ({ ...t, colors: selectTheme })}
        styles={customStyles}
        onChange={(val: any) => {
          onChange(name || '', val)
        }}
        closeMenuOnSelect={!multiselect}
        getOptionLabel={o => (optionsLabel ? o[optionsLabel] : o['label'])}
        getOptionValue={o => (optionsValue ? o[optionsValue] : o['value'])}
      />
      {validationError !== undefined && (
        <ValidationError className="validation-error">
          {validationError}
        </ValidationError>
      )}
    </Container>
  )
}

export default Select
