import React from 'react'
import styled from 'styled-components'
import './FullScreenLoader.css'
import glassIcon from './glass.png'
import beer from './beer.png'

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  span {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    top: calc(50% + 80px);
    font-size: 18px;
  }
`

const FullScreenLoader: React.FC = () => (
  <Container>
    <div id="beer2">
      <div className="cup" style={{ backgroundImage: `url(${beer})` }}>
        <div style={{ backgroundImage: `url(${glassIcon})` }}></div>
        {[...Array(40)].map((n, i) => (
          <i key={i} className="bubble"></i>
        ))}
      </div>
    </div>
    <span>Brewing...</span>
  </Container>
)

export default FullScreenLoader
