import React from 'react'
import styled from 'styled-components'
import ClearIcon from 'assets/icon/clear@2x.png'
import theme from 'theme'

const InputContainer = styled.div`
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  .input-wrapper {
    margin-bottom: 15px;
  }
  &.error {
    input {
      border: 1px solid ${theme.status.dangerLight};
      &:focus {
        box-shadow: 0px 0px 0.5px 2px ${theme.status.dangerLight},
          0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
      }
    }
    .input-wrapper {
      margin-bottom: 0;
    }
  }
  &.disabled {
    label {
      color: ${theme.text.disabled};
    }
    input {
      box-shadow: inset 0 0 0 1px rgba(151, 151, 151, 0.09);
      opacity: 0.5;
    }
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }
`

const Label = styled.label`
  font-weight: bold;
  font-size: 0.75rem;
  color: ${theme.text.body};
  span {
    display: block;
    padding-bottom: 5px;
  }
`

const ValidationError = styled.span`
  font-size: 0.75rem;
  color: ${theme.status.danger};
`

const Input = styled.input`
  display: flex;
  min-height: 32px;
  padding: 9px 35px 9px 11px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.06),
    inset 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 0.7rem;
  background-color: #f4f5f6;
  color: ${theme.text.input};
  font-size: 13px;
  border: 1px solid transparent;
  cursor: ${props => (props.disabled === true ? 'not-allowed' : 'default')};
  &:focus {
    outline: none;
    box-shadow: 0px 0px 0.5px 3px rgba(121, 188, 29, 0.7),
      0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
    background-color: #f4f5f6;
    border: 1px solid rgba(121, 188, 29, 0.7);
    &:hover {
      box-shadow: 0px 0px 0.5px 3px rgba(121, 188, 29, 0.7),
        0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
    }
  }
  &:active {
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.05),
      inset 0 0 0 1px rgba(151, 151, 151, 0.09);
    background-color: rgba(226, 234, 238, 0.77);
  }
  &:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 0 0 rgba(0, 0, 0, 0.05);
    background-color: rgba(167, 167, 180, 0.12);
  }
  &::placeholder {
    font-size: 13px;
    color: rgba(112, 112, 112, 0.35);
  }
  &::-ms-clear {
    display: none;
  }
`

const ClearButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 0;
  border-radius: 5rem;
  width: 20px;
  height: 20px;
  img {
    max-height: 14px;
    max-width: 14px;
    border-radius: 5rem;
    border: 3px solid transparent;
  }
  &:focus {
    outline: 0;
    img {
      border: 3px solid rgba(67, 152, 9, 0.44);
    }
  }
`

interface Props {
  disabled?: boolean
  required?: boolean
  maxLength?: number
  validationError?: string
  placeholder?: string
  autocomplete?: boolean
  value: string
  label?: string
  type?: 'text' | 'tel' | 'email'
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

const InputText: React.FC<Props> = props => {
  const {
    disabled,
    required,
    maxLength,
    validationError,
    placeholder,
    value,
    label,
    type,
    autocomplete,
    onChange,
    onFocus,
    onBlur,
  } = props

  const defaultAutocomplete =
    autocomplete === undefined || autocomplete === true
      ? {}
      : { autoComplete: 'new-password' }

  const defaultMaxLength = 150

  return (
    <InputContainer
      className={`input ${validationError ? 'error' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      <Label>
        {label && <span>{`${label}${required ? ' *' : ''}`}</span>}
        <div className="input-wrapper">
          <Input
            className={`${label !== undefined ? 'has-label' : ''}`}
            disabled={disabled}
            maxLength={maxLength || defaultMaxLength}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            {...defaultAutocomplete}
            type={type || 'text'}
          />

          {value.length > 0 && (
            <ClearButton
              type="button"
              onClick={() => {
                onChange({
                  target: {
                    value: '',
                  },
                } as React.ChangeEvent<HTMLInputElement>)
              }}
              tabIndex={-1}
            >
              <img src={ClearIcon} alt="clear icon" />
            </ClearButton>
          )}
        </div>
      </Label>

      {validationError && <ValidationError>{validationError}</ValidationError>}
    </InputContainer>
  )
}

export default InputText
