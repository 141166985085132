import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './Toast.scss'

interface Props {
  time?: number
}

export const show = toast

const Toast: React.FC<Props> = props => {
  const { time } = props
  return (
    <ToastContainer
      className="toast"
      position="bottom-center"
      closeOnClick={true}
      hideProgressBar={true}
      pauseOnHover={false}
      autoClose={time || 3000}
    />
  )
}

export default Toast
