import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import RadioButton from 'components/FormElements/RadioButton'
import Checkbox from 'components/FormElements/Checkbox'
import InputText from 'components/FormElements/InputText'

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  .radio-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 52px;
    padding-left: 14px;
    background-color: ${theme.background.defaultAlt};
    border: 1px solid ${theme.background.defaultAlt};
    border-radius: 0.75rem;
    &:hover {
      background-color: ${theme.background.default};
      border: 1px solid ${theme.background.default};
      box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.4);
    }
    &.selected {
      background-color: ${theme.background.defaultSelected};
      border: 1px solid ${theme.background.defaultSelected};
    }
    &:first-child {
      margin-right: 8px;
    }
  }
`

const ValidationError = styled.span`
  font-size: 0.75rem;
  color: ${theme.status.danger};
`

const Label = styled.label`
  font-weight: bold;
  font-size: 0.75rem;
  color: ${theme.text.body};
  span {
    display: block;
    padding-bottom: 5px;
  }
`

const Container = styled.div`
  padding: 0.5rem;
  .content-container {
    display: flex;
    flex-wrap: wrap;
    .label-container {
      flex-basis: 40%;
      margin-top: 1rem;
      h4 {
        white-space: nowrap;
        color: #484848;
      }
    }
    .fields-container {
      flex-basis: 60%;
      min-width: 320px;
      margin-bottom: 1.75rem;
      .more-outlets-checkbox {
        .checkbox {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          cursor: pointer;
          width: 100%;
          height: 52px;
          padding-left: 14px;
          background-color: ${theme.background.defaultAlt};
          border: 1px solid ${theme.background.defaultAlt};
          border-radius: 0.75rem;
          &:hover {
            background-color: ${theme.background.default};
            border: 1px solid ${theme.background.default};
            box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.4);
          }
          &.selected {
            background-color: ${theme.background.defaultSelected};
            border: 1px solid ${theme.background.defaultSelected};
          }
          label {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 950px) {
    .options-card-container {
      .option-one {
        display: flex;
        flex-basis: calc(50% - 1rem);
        button {
          margin: 0;
          max-width: 350px;
        }
      }
      .option-two {
        display: flex;
        flex-basis: calc(50% - 1rem);
        button {
          margin: 0;
          max-width: 350px;
        }
      }
    }
  }
`

interface Values {
  newCustomer: boolean | null
  soldTo: string
  moreOutlets: boolean
}
interface Errors {
  newCustomer?: string
  moreOutlets?: string
  soldTo?: string
}

interface Props {
  values: Values
  errors: Errors
  onChange: (field: string, value: boolean | string) => void
}

const CustomerDetails: React.FC<Props> = ({ values, errors, onChange }) => {
  const { newCustomer, moreOutlets, soldTo } = values

  return (
    <Container>
      <div className="content-container">
        <div className="label-container">
          <h4 className="bold">Customer Details</h4>
        </div>
        <div className="fields-container">
          <Container>
            <div className="fields-container">
              <Label>{`Existing Customer? *`}</Label>
              <div className={`${errors && errors.newCustomer ? 'error' : ''}`}>
                <OptionsContainer className="options-container">
                  <RadioButton
                    label="Yes"
                    option={false}
                    value={newCustomer}
                    onChange={value => {
                      onChange('newCustomer', value)
                      onChange('soldTo', '')
                    }}
                  />
                  <RadioButton
                    label="No"
                    option={true}
                    value={newCustomer}
                    onChange={value => {
                      onChange('newCustomer', value)
                      onChange('soldTo', '')
                    }}
                  />
                </OptionsContainer>
                {errors.newCustomer && errors.newCustomer && (
                  <ValidationError>{errors.newCustomer}</ValidationError>
                )}
              </div>
            </div>
          </Container>
          {newCustomer === false && (
            <InputText
              label="Your 'Sold-to' number"
              value={soldTo}
              onChange={e => {
                onChange('soldTo', e.target.value)
              }}
              required={true}
              validationError={errors.soldTo}
            />
          )}
          <div className="more-outlets-checkbox">
            <Checkbox
              label="I want to sign-up more than one outlet"
              value={moreOutlets}
              onChange={e => {
                onChange('moreOutlets', e.target.checked)
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CustomerDetails
