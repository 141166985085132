import React, { useEffect } from 'react'
import styled from 'styled-components'
import theme from 'theme'
import Checkbox from 'components/FormElements/Checkbox'
import InputText from 'components/FormElements/InputText'
import Card from 'components/Card'
import SDCard from 'components/SDCard'
import Option1Pressed from 'assets/icon/option-1-pressed.png'
import Option2Pressed from 'assets/icon/option-2-pressed.png'
import Option3Pressed from 'assets/icon/option-3-pressed.png'
import GlobalContext from 'contexts/GlobalContext'

const Container = styled.div`
  padding: 0.5rem;
  .options-card-container {
    display: flex;
    flex-wrap: no-wrap;
    justify-content: center;
    width: 100%;
    padding-bottom: 35px;
    a {
      text-decoration: none;
    }
    .option-one {
      display: flex;
      justify-content: center;
      margin: 5px 5px;
      &.inactive {
        button {
          background-image: none !important;
          background-color: #fff;
          border: 1px solid ${theme.cta.primaryDark};
        }
        .content-card {
          span {
            color: ${theme.cta.primaryDark};
          }
          h4 {
            color: ${theme.text.splashScreen};
          }
        }
      }
    }
    .option-two {
      display: flex;
      justify-content: center;
      margin: 5px 5px;
      &.inactive {
        button {
          background-image: none !important;
          background-color: #fff;
          border: 1px solid ${theme.cta.primaryDark};
        }
        .content-card {
          span {
            color: ${theme.cta.primaryDark};
          }
          h4 {
            color: ${theme.text.splashScreen};
          }
        }
      }
    }

    .option-three {
      display: flex;
      justify-content: center;
      margin: 5px 5px;
      &.inactive {
        button {
          background-image: none !important;
          background-color: #fff;
          border: 1px solid ${theme.cta.primaryDark};
        }
        .content-card {
          span {
            color: ${theme.cta.primaryDark};
          }
          h4 {
            color: ${theme.text.splashScreen};
          }
        }
      }
    }
  }
  .content-container {
    display: flex;
    flex-wrap: wrap;
    .label-container {
      flex-basis: 40%;
      margin-top: 1rem;
      h4 {
        white-space: nowrap;
        color: #484848;
      }
    }
    .fields-container {
      flex-basis: 60%;
      min-width: 320px;
      margin: 1rem 0 1.75rem 0;
      .more-outlets-checkbox {
        .checkbox {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          cursor: pointer;
          width: 100%;
          height: 52px;
          padding-left: 14px;
          background-color: ${theme.background.defaultAlt};
          border: 1px solid ${theme.background.defaultAlt};
          border-radius: 0.75rem;
          &:hover {
            background-color: ${theme.background.default};
            border: 1px solid ${theme.background.default};
            box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.4);
          }
          &.selected {
            background-color: ${theme.background.defaultSelected};
            border: 1px solid ${theme.background.defaultSelected};
          }
          label {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 601px) and (max-width: 950px) {
    .options-card-container {
      flex-wrap: wrap;
      .option-one {
        display: flex;
        button {
          margin: 0;
          max-width: 350px;
        }
      }
      .option-two {
        display: flex;
        button {
          margin: 0;
          max-width: 350px;
        }
      }
      .option-three {
        display: flex;
        button {
          margin: 0;
          max-width: 350px;
        }
      }
    }
  }

  @media screen and (min-width: 409px) and (max-width: 600px) {
    .options-card-container {
      flex-wrap: wrap;
      .option-one {
        display: flex;
        flex-basis: calc(100% - 1rem);
        button {
          margin: 0;
          max-width: 380px;
        }
      }
      .option-two {
        display: flex;
        flex-basis: calc(100% - 1rem);
        button {
          margin: 0;
          max-width: 380px;
        }
      }
      .option-three {
        display: flex;
        flex-basis: calc(100% - 1rem);
        button {
          margin: 0;
          max-width: 380px;
        }
      }
    }
  }

  @media screen and (max-width: 410px) {
    .options-card-container {
      display: flex;
      flex-wrap: wrap;
      .option-one {
        display: flex;
        flex-basis: calc(100% - 8px);
        button {
          margin: 0;
          max-width: none;
        }
      }
      .option-two {
        display: flex;
        flex-basis: calc(100% - 8px);
        button {
          margin: 0;
          max-width: none;
        }
      }
      .option-three {
        display: flex;
        flex-basis: calc(100% - 8px);
        button {
          margin: 0;
          max-width: none;
        }
      }
    }
  }
`
interface Values {
  newCustomer: boolean | null
  moreOutlets: boolean
  soldTo: string
}

interface Errors {
  soldTo?: string
  newCustomer?: string
}

interface Props {
  values: Values
  errors: Errors
  onChange: (field: string, value: string | boolean) => void
}

const OptionsDetails: React.FC<Props> = (props) => {
  const { values, errors, onChange } = props
  const { newCustomer, soldTo, moreOutlets } = values

  const { setApplicationState, currentApplicationState } =
    GlobalContext.Consumer()

  useEffect(() => {
    setApplicationState()
  })

  const scrollToContent = () => {
    setTimeout(() => {
      const elem = document.getElementsByClassName('content-container')
      elem.length > 0 &&
        elem[0].scrollIntoView({
          inline: 'start',
          behavior: 'smooth',
        })
    }, 20)
  }

  return (
    <Container>
      <div className="options-card-container">
        <div
          className={`option-one ${
            (currentApplicationState === 'DIRECT' &&
              newCustomer !== null &&
              newCustomer === false) ||
            currentApplicationState === 'SMART-DISPENSE'
              ? 'inactive'
              : ''
          }`}
        >
          <Card
            image={Option1Pressed}
            label="Looking to join us?"
            text="New to HEINEKEN? Add us as a supplier"
            option={true}
            value={newCustomer}
            onChange={(val) => {
              const scroll = newCustomer === null
              onChange('newCustomer', val)
              onChange('soldTo', '')
              if (scroll) {
                scrollToContent()
              }
            }}
          />
        </div>
        <div
          className={`option-three ${
            (currentApplicationState === 'DIRECT' && newCustomer === true) ||
            currentApplicationState === 'SMART-DISPENSE'
              ? 'inactive'
              : ''
          }`}
        >
          <Card
            image={Option2Pressed}
            label="Already in?"
            text="Existing customer? Add an additional outlet"
            option={false}
            value={newCustomer}
            onChange={(val) => {
              const scroll = newCustomer === null
              onChange('newCustomer', val)
              if (scroll) {
                scrollToContent()
              }
            }}
          />
        </div>

        <div
          className={`option-three ${
            newCustomer === null || currentApplicationState === 'SMART-DISPENSE'
              ? ''
              : 'inactive'
          }`}
        >
          <SDCard
            image={Option3Pressed}
            text="Interested in HEINEKEN SmartDispense™"
            value={null}
          />
        </div>
      </div>
      {currentApplicationState === 'DIRECT' && newCustomer !== null && (
        <div className="content-container">
          <div className="label-container">
            <h4 className="bold">{`${
              newCustomer ? 'New Customer Details' : 'Existing Customer Details'
            }`}</h4>
          </div>
          <div className="fields-container">
            {!newCustomer && (
              <InputText
                label="Your 'Sold-to' number"
                value={soldTo}
                onChange={(e) => {
                  onChange('soldTo', e.target.value)
                }}
                required={true}
                validationError={errors.soldTo}
              />
            )}
            <div className="more-outlets-checkbox">
              <Checkbox
                label="I want to sign-up more than one outlet"
                value={moreOutlets}
                onChange={(e) => {
                  onChange('moreOutlets', e.target.checked)
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

export default OptionsDetails
