interface ThemeInterface {
  text: TextInterface
  background: BackgroundInterface
  cta: CallToActionInterface
  status: StatusInterface
}

interface TextInterface {
  heading: string
  body: string
  secondary: string
  disabled: string
  input: string
  splashScreen: string
}

interface BackgroundInterface {
  hr: string
  default: string
  defaultAlt: string
  defaultSelected: string
  defaultPanel: string
  defaultNavA: string
  defaultNavB: string
  defaultCheckbox: string
  defaultRadio: string
}

interface CallToActionInterface {
  primary: string
  primaryLight: string
  primaryPunchy: string
  primaryDark: string
  secondary: string
  secondaryLight: string
  secondaryPunchy: string
  secondaryDark: string
}

interface StatusInterface {
  success: string
  successPale: string
  successLight: string
  successDark: string
  warning: string
  warningPale: string
  warningLight: string
  warningDark: string
  danger: string
  dangerPale: string
  dangerLight: string
  dangerDark: string
}

export default {
  text: {
    heading: '#000',
    body: '#0c1826',
    secondary: '#97979e',
    disabled: 'rgba(112, 112, 112, 0.31)',
    input: '#222222',
    splashScreen: '#484848',
  },
  background: {
    hr: '#e7e7e7',
    default: '#fff',
    defaultAlt: '#f7f7f8',
    defaultSelected: '#d9dddd',
    defaultPanel: '#cee1f4',
    defaultNavA: 'rgba(249, 249, 249, 0.94)',
    defaultNavB: 'rgba(249, 249, 249, 0.94)',
    defaultCheckbox: '#eeeeef',
    defaultRadio: '#222224',
  },
  cta: {
    primary: '#439809',
    primaryLight: '#a8d91b',
    primaryPunchy: '#79bc1d',
    primaryDark: '#006e31',
    secondary: '#26386b',
    secondaryLight: '#26386b',
    secondaryPunchy: '#040b34',
    secondaryDark: '#01041c',
  },
  status: {
    success: '#79bc1d',
    successPale: '#97d02c',
    successLight: '#b0e238',
    successDark: '#317f00',
    warning: '#fd7306',
    warningPale: '#ffe199',
    warningLight: '#febe0f',
    warningDark: '#c92d00',
    danger: '#ff4d39',
    dangerPale: '#ff775c',
    dangerLight: '#ff7b4e',
    dangerDark: '#cc0500',
  },
} as ThemeInterface
